/// <reference path='../scaffolding/Component.ts'/>

class MobileMenu extends Component {

    private menuBtn:JQuery;
    private isOff:boolean = true;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.mobileMenuWrapper').clone());
        this.menuBtn = this.container.find('.mobileMenu').on('click', ()=>this.toggleMenu());

        this.container.find('.homeBtn').on('click', ()=>this.homeClicked());
    }

    public showMe(){
        super.showMe();
        // this.content.removeClass('off');
    }

    private toggleMenu(){    
        console.log(this.content)
        if(this.isOff){
            this.content.removeClass('off');
            this.isOff =  false;
        } else {
            this.content.addClass('off');
            this.isOff =  true;
        }
    }

    private homeClicked(){
        this.toggleMenu();
        if(VideoView.PHASE == VideoView.PHASE_TWO && !Main.phaseTwoLive){
            page("/phasetwo");
        } else {
            page("/");
        }
        EventBus.dispatch(Header.TOGGLE_MENU);
    }

    public hideMe(){    
        super.hideMe();
        // this.content.addClass('off');
    }
}
