/// <reference path='../scaffolding/Utils.ts'/>
/// <reference path='../scaffolding/EventBus.ts'/>
/// <reference path='./VideoControls.ts'/>


class VideoPlayer extends Component{

    public static VIDEO_COMPLETE:string = "videoover";
    public static SHOW:string = "showvideoplayer";
    public static RESET:string = "resetvideoplayer";
    public static PLAY:string = "playvideo";

    private player:JQuery;
    private btnCreate:JQuery;
    private videoOptions:JQuery;
    private thanksMobile:JQuery;
    private mobilePlayBtn: JQuery;
    private vidWidth:number = 1280;
    private vidHeight:number = 720;
    private maxWidth:number = 1920;
    private gaFired:number = 0;
    private startHeight:any;
    private startWidth:any;
    private aspectRatio:number;
    private progressInterval:number;
    private video:HTMLVideoElement;
    private isFullscreen:boolean = false;
    private isPlaying: boolean = false;
    private controlHideTimeout:number;
    private videoControls:VideoControls;
    private gaPhase:string = 'phase_one_video';

    constructor(container, data, id){

        super(container, data, id);
        
        this.setContent(Main.templates.find('.videoPlayer').clone());

        this.video = <HTMLVideoElement>this.content.find('.player')[0];

        this.player = this.content.find('.player');
        this.videoOptions = this.content.find('.videoOptions')
        this.thanksMobile = this.content.find('.thanksMobile')

        this.btnCreate = this.content.find('.btnCreate').on('click', ()=>this.createAnother());

        this.content.find('.facebook').bind('click', (e)=>this.delegate.shareFacebook(e));
        this.content.find('.twitter').bind('click', (e)=>this.delegate.shareTwitter(e));
        this.content.find('.mailLink').bind('click', (e)=>this.delegate.shareEmail(e));
        this.content.find('.download').bind('click', (e)=>this.delegate.shareDownload(e));

        EventBus.addEventListener(VideoPlayer.SHOW, ()=>this.showMe(), this);
        EventBus.addEventListener(VideoPlayer.RESET, ()=>this.reset(), this);
        EventBus.addEventListener(VideoPlayer.PLAY, ()=>this.playVideo(), this);
        EventBus.addEventListener(DynamicVideoView.PHASE_SET, ()=>this.setPhase(), this);
  
        this.video.addEventListener("ended", ()=> { 
            if(Main.mobile){
                this.thanksMobile.removeClass('hide');
                if(VideoView.PHASE == VideoView.PHASE_TWO){
                    this.video.setAttribute("poster","/img/video_poster_two.jpg");
                } else {

                    this.video.setAttribute("poster","/img/video_poster.jpg");
                }
                this.video.currentTime = 0;
                this.video.load(); 
            }else{             
                ga('send', 'event', this.gaPhase, 'playback','progress', 1);
                ga('send', 'event', this.gaPhase, 'progress', 'complete');
                this.dispatch(VideoPlayer.VIDEO_COMPLETE);
            }
        });

        if(!Main.mobile){

            this.videoControls = new VideoControls(this.content.find('.controlContainer'), {
                'video':this.video
            }, this);
            EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, ()=>this.enterFullscreen(), this);
            EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, ()=>this.exitFullscreen(), this);
            EventBus.addEventListener(VideoControls.PAUSE, ()=>this.pauseVideo(), this);
            EventBus.addEventListener(VideoControls.PLAY, ()=>this.playVideo(), this);
            EventBus.addEventListener(VideoControls.NAVIGATE_TO, (t)=>this.navigateToTime(t), this);

        } else {

            this.video.setAttribute("controls","controls");
            this.video.setAttribute("poster","/img/video_poster_two.jpg");
            if (VideoView.PHASE == VideoView.PHASE_TWO){
                this.video.setAttribute("poster","/img/video_poster_two.jpg");
            }
            this.content.find('.videoBg').hide();
            this.showOptions();
        }

        $(window).bind('resize orientationchange', ()=>this.resize());
        this.resize();
    }

    private checkKey(e){
        if(e.keyCode ==32){
            if(this.videoControls.isPlaying){
                this.pauseVideo()
            } else {
                this.playVideo();
            }
        }
    }

    private setPhase(){
        if(VideoView.PHASE == VideoView.PHASE_TWO){
            this.btnCreate.html("Create Another");
            this.gaPhase = 'phase_two_video';
        } 
    }

    public reset(){

        this.video.currentTime = 0;
    }

    private navigateToTime(t){

        this.video.currentTime = t;
    }

    public createAnother(){

        Main.isOwner = false;
        VideoView.VIN = "";
        let route = (!Main.phaseTwoLive && VideoView.PHASE == VideoView.PHASE_TWO) ? '/photo' : '/info';
        ga('send', 'event', this.gaPhase, 'click', 'create_more');
        page(route);
    }

    public showMe(){
        super.showMe();
        if(!Main.mobile){
            clearTimeout(this.controlHideTimeout);
            this.content.on('mousemove', ()=>this.mouseMove());
            $(document).on('keypress', (e)=>this.checkKey(e))
        }else {
            EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundController.WHITE);       
        }
    }

    private mouseMove(){

        clearTimeout(this.controlHideTimeout);
        this.controlHideTimeout = setTimeout(()=>this.hideOptions(), 1800);
        this.showOptions();
    }

    private showOptions(){
        this.videoOptions.addClass('show');
    }

    private hideOptions(){
        this.videoOptions.removeClass('show');
    }

    
    public setThanks(){

    }

    private videoOver(){
        this.dispatch(VideoPlayer.VIDEO_COMPLETE);
    }

    public playVideo(){

        this.video.play();
    }

    public pauseVideo(){
        this.video.pause();
    }
    
    public loadVideo(src){
        this.video.src = src;

        if(!Main.mobile){
            this.playVideo();
        }

        ga('send', 'event', this.gaPhase, 'playback', 'start');

        this.gaFired = 0;

        clearInterval(this.progressInterval)
        this.progressInterval = setInterval(()=>{
            let perc = this.video.currentTime/this.video.duration;
            if (perc >= 0.75 && this.gaFired == 2) {
                this.gaFired = 3;
                ga('send', 'event', this.gaPhase, 'playback', 'progress', 0.75);
            } else if (perc >= 0.50 && this.gaFired == 1) {
                this.gaFired = 2;
                ga('send', 'event', this.gaPhase, 'playback', 'progress', 0.50);
            } else if (perc >= 0.25 && this.gaFired == 0) {
                this.gaFired = 1;
                ga('send', 'event', this.gaPhase, 'playback', 'progress', 0.25);
            }
        }, 33);

        if(Main.mobile){
            this.content.find('.optionContainer').show();
        }
    }

    public hideMe(){

        super.hideMe();
        clearInterval(this.progressInterval)
        this.pauseVideo();
        if(!Main.mobile){
            $(document).off('keypress')
            this.hideOptions();
            this.content.off('mousemove');
            clearTimeout(this.controlHideTimeout);
        }
    }

    public resize(){

        //Desktop
        if(!Main.mobile){
            let h = (this.isFullscreen) ? 0 : $('.header').height();

            //make the player fullscreen
            var pos = Utils.fitToContainer({
                'containerWidth':Main.sWidth,
                'containerHeight':Main.sHeight - h,
                'contentWidth':this.vidWidth,
                'contentHeight':this.vidHeight,
                'vAlign':'center',
                'hAlign':'center',
                'scaleMode':'proportionalInside'
            });

            pos.top+=h;
            this.player.css(pos);

            //make the content fullscreen
            this.content.css({
                'width':Main.sWidth,
                'height':Main.sHeight
            });

        }else{

            let pos;
            //if landscape, fill screen
            if(Main.sWidth > Main.sHeight){

                let h = $('.header').height();
                pos = {
                    'width':Main.sWidth,
                    'height':Main.sHeight - h
                }

                this.container.addClass('landscape');
                this.content.css('height', 'auto');

            //if not, make it full width and make the content full height (white background)
            }else{

                let r = this.vidWidth / this.vidHeight;
                let w = screen.width;
                pos = {
                    'width':screen.width,
                    'height':screen.width/ r
                }

                this.container.removeClass('landscape');
                this.content.css('height', screen.height);
            }
            this.player.css(pos);
        } 
    }

    private enterFullscreen(){
        this.isFullscreen = true;
        this.resize();
    }   

    private exitFullscreen(){
        this.isFullscreen = false;
        this.resize();
    }

    public removeExpIdFromUrl(){
        let path =  this.data.route
        if(this.data.route == VideoView.PHASE_TWO){
            path = '/' + this.data.route
        } 
        page(path);
    }    
}
