/// <reference path='../scaffolding/Component.ts'/>

class VideoControls  extends Component{

    public static ENTER_FULLSCREEN:string = "enterfullscreen";
    public static EXIT_FULLSCREEN:string = "exitfullscreen";
    public static NAVIGATE_TO:string = "navigatetotime";
    public static PAUSE:string = "pauseclicked";
    public static PLAY:string = "playclicked";

    private btnPlay:JQuery;
    private btnPause:JQuery;
    private btnMute:JQuery;
    private btnUnmute:JQuery;
    private btnEnterFS:JQuery;
    private btnExitFS:JQuery;
    private bar:JQuery;
    private progress:JQuery;
    private dragger:JQuery;

    private videoDiv:HTMLVideoElement;

    private duration:number = 0;
    private currentTime:number = 0;
    private dragPerc:number;
    private barWidth:number = 395;
    private dragging:boolean = false;
    public isPlaying:boolean = false;
    private isMuted:boolean = false;
    private playInterval:number;
    private dragOffset:number = 0;

    constructor(container, data, delegate){

        super(container, data ,delegate);

        this.setContent(Main.templates.find('.videoControls').clone());

        this.bindEvents();
    }

    public bindEvents(){

        //buttons
        this.btnPlay = this.content.find('.btnPlay').bind('click', ()=>this.playClicked());
        this.btnPause = this.content.find('.btnPause').bind('click', ()=>this.pauseClicked());
        this.btnMute = this.content.find('.btnMute').bind('click', $.proxy(this.toggleMute, this));
        this.btnUnmute = this.content.find('.btnUnmute').bind('click', $.proxy(this.toggleMute, this));
        this.btnEnterFS = this.content.find('.btnEnterFullscreen').bind('click', ()=>this.toggleFullscreen());
        this.btnExitFS = this.content.find('.btnExitFullscreen').bind('click', ()=>this.toggleFullscreen());
        this.progress = this.content.find('.progress').bind('click', $.proxy(this.skipTo, this));
        this.videoDiv =  this.data.video;
        this.dragger =  this.content.find('.dragger').on('mousedown touchstart', (e)=>this.startDragging(e))
        this.bar = this.content.find('.bar');

        $(this.videoDiv).on('play', ()=>this.videoPlaying());
        $(this.videoDiv).on('pause', ()=>this.videoPaused());
        $(this.videoDiv).on('ended', ()=>this.videoOver());
        $(this.videoDiv).on('loadedmetadata', ()=>this.setDuration());

        $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', ()=>this.fullscreenchange());
    }

    private setDuration(){
        this.duration = this.videoDiv.duration;
    }

    private playClicked(){
        EventBus.dispatch(VideoControls.PLAY);
    }

    private pauseClicked(){
        EventBus.dispatch(VideoControls.PAUSE);
    }

    private videoPlaying(){
        this.isPlaying = true;
        this.btnPause.show();
        this.btnPlay.hide();
        clearInterval(this.playInterval);
        this.playInterval = setInterval($.proxy(this.checkTime, this), 33);
    }

    private videoPaused(){
        this.isPlaying = false;
        this.btnPlay.show();
        this.btnPause.hide();
        clearInterval(this.playInterval);
    }

    //mutes the video and shows / hides the correct UI
    public toggleMute(){

        if(this.isMuted){
            this.btnMute.show();
            this.btnUnmute.hide();
            this.videoDiv.volume = 1;
            this.isMuted = false;

        } else {
            this.btnMute.hide();
            this.btnUnmute.show();
            this.videoDiv.volume = 0;
            this.isMuted = true;

        }
    }

    private videoOver(){

        this.isPlaying = false;
        this.btnPause.hide();
        this.btnPlay.show();
        clearInterval(this.playInterval);
    }

    //checks the video time and updates the progress bar
    public checkTime(){
        this.currentTime = this.videoDiv.currentTime;
        let percentDone = this.currentTime/this.duration*100;

        if(!this.dragging){
            this.bar.css('width', percentDone+'%');
            this.dragger.css('left', percentDone+'%');    
        }
        
    }

    private startDragging(e){
        
        e.preventDefault();
        e.stopPropagation();

        this.dragging = true;
        this.dragOffset = (e.clientX - this.dragger.offset().left) - (this.dragger.width() / 2);

        $(window).on('mousemove', (e)=>this.moveDragger(e));
        $(window).one('mouseup', (e)=>this.stopDragging(e));

    }

    private stopDragging(e){

        e.preventDefault();
        e.stopPropagation();

        this.dragging = false;
        $(window).off('mousemove');
    }

    private moveDragger(e){

        e.preventDefault();
        e.stopPropagation();
        
        //get the position of the dragger
        let xpos = e.clientX -this.progress.offset().left - this.dragOffset;

        xpos = Math.max(xpos, 0);
        xpos = Math.min(xpos, this.barWidth);
        
        //set the time of the video
        let perc = xpos/this.barWidth*100;
        this.setTimeFromPercentage(perc);
    }

    private setTimeFromPercentage(perc){

        let time = this.duration * perc / 100;
        EventBus.dispatch(VideoControls.NAVIGATE_TO, time);

        this.currentTime = time;

        let l = (this.barWidth * perc / 100);
        this.dragger.css('left', l + 'px');
        this.bar.css('width', l + 'px');
    }

    //jumps to a specific point in the video
    public skipTo(e){

        let xpos = e.clientX -this.progress.offset().left;
        let perc = xpos/this.barWidth*100;
        this.setTimeFromPercentage(perc);
    }

    //called when we exit fullscreen    
    public exitFullscreen(){
        this.btnEnterFS.show();
        this.btnExitFS.hide();
        EventBus.dispatch(VideoControls.EXIT_FULLSCREEN);
    }

    //called when we enter fullscreen
    public enterFullscreen(){
        this.btnEnterFS.hide();
        this.btnExitFS.show();
        EventBus.dispatch(VideoControls.ENTER_FULLSCREEN);
    }

    //called when the browser fullscreen change event fires
    public fullscreenchange(){

        var doc = window.document;
        if(!doc["fullscreenElement"] && !doc["mozFullScreenElement"] && !doc["webkitFullscreenElement"] && !doc["msFullscreenElement"]){
            this.exitFullscreen();
        }
    }

       //either enters or exits fullscreen mode
    public toggleFullscreen(){
        var doc = window.document;
        var docEl = $("#container")[0];

        var requestFullScreen = docEl["requestFullscreen"] || docEl["mozRequestFullScreen"] || docEl["webkitRequestFullScreen"] || docEl["msRequestFullscreen"];
        var cancelFullScreen = doc["exitFullscreen"] || doc["mozCancelFullScreen"] || doc["webkitExitFullscreen"] || doc["msExitFullscreen"];

        if(!doc["fullscreenElement"] && !doc["mozFullScreenElement"] && !doc["webkitFullscreenElement"] && !doc["msFullscreenElement"]) {
            requestFullScreen.call(docEl);
            this.enterFullscreen();
              
        }
        else {
            cancelFullScreen.call(doc);
            this.exitFullscreen();
        }
    }
}

