/// <reference path='../scaffolding/Component.ts'/>

class Success extends Component {

    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent(Main.templates.find('.successWrap').clone());

        this.hideDelay = 500;
    }
}
