/// <reference path='../scaffolding/Component.ts'/>

class Footer extends Component {

    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent($("#container").find('.footer'));

        EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, ()=>this.showMe(), this);
        EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, ()=>this.hideMe(), this);
    }
}
