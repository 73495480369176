class Loader extends Component{

    private loaderCircle:JQuery;
    private loadPerc:JQuery;
    private loaderDiv:JQuery;
    private circleDiv:JQuery;

    private cycleTextHolder:JQuery;
    private cycleText:JQuery;

    private cyclingText:boolean = false;

    private cycleCount:number = 0;
    private linesCount:number = 0;
    private cycleTimeout:number;
    private cycleDelay:number = 7000;
    private progrossTimeout:number;
    private startNum:number = 0;

    constructor(container, data, del){

        super(container, data ,del);

        this.setContent(Main.templates.find('.loader').clone());
        this.loaderCircle = this.content.find('#fill-circle');
        this.loadPerc = this.content.find('.loadPerc');
        this.loaderDiv = this.content.find('.loader')
        this.circleDiv =  $(this.loaderCircle[0]);

        $(window).on('resize orientationchange', () => this.resize());
        this.resize();
    }

    public updatePercentage(perc){
        if(!this.cyclingText){
            this.cycleLoadText();
            this.cyclingText = true;
            clearTimeout(this.progrossTimeout);
            this.progressCircle(perc)
        } 
        if(perc == 1) {            
            clearTimeout(this.progrossTimeout);
            this.progressCircle(perc)
        }
    }

    public progressCircle(perc){
        let percNum:any = Math.floor(this.startNum*100);
        if(percNum<10 && percNum !=0){
            percNum = "0"+percNum+"%"
        } else {
            percNum = percNum+"%";
        }
        this.loadPerc.html(percNum);
        this.startNum = this.startNum+0.01
        let nPerc = this.startNum;
        if(nPerc <1){
            this.progrossTimeout = setTimeout(()=>{this.progressCircle(nPerc)}, 800);
        } 
        if(!Main.isIE){
            this.circleDiv.attr('class','animate');
        }else{
            let num = Math.floor(720 * perc);
            this.loaderCircle.css('stroke-dasharray', num + ',2000');
        }
        if(perc == 1) {
            this.loadPerc.html("100%");
            this.startNum =0;
            let delay =  500;
            if(Main.mobile){
                delay = 0;
            }
            if(!Main.isIE){
                this.circleDiv.attr('class','');
                this.circleDiv.attr('class','complete');    
            }else{
                this.loaderCircle.css('stroke-dasharray', '725,2000');
            }
            setTimeout(()=>{this.hideMe()}, delay);
        }
    }

    public reset(){
        this.circleDiv.attr('class','');    }

    private cycleLoadText(){
      
        if(VideoView.PHASE == VideoView.PHASE_ONE){
            this.cycleTextHolder = this.content.find('.copy')
        } else {
            this.cycleTextHolder =  this.content.find('.copyPT')
        }
          
        this.cycleTextHolder.show();
        this.cycleText =  $(this.cycleTextHolder).find('.line');
        this.linesCount = this.cycleText.length;
        setTimeout(()=>{this.beginCycling()}, 500);
    }

    private beginCycling(){

        this.cycleText.addClass('hide');

        let textToShow = this.cycleTextHolder[0].children[this.cycleCount];
        $(textToShow).removeClass('hide');
        
        this.cycleCount++;
        if(this.cycleCount == this.linesCount){
            this.cycleCount = 0;
        }
        clearTimeout(this.cycleTimeout)
        this.cycleTimeout = setTimeout(()=>{this.beginCycling()}, this.cycleDelay);
    }

    private resetCycleText(){
        clearTimeout(this.cycleTimeout);
        this.cycleCount = 0;
        if(this.cycleText){
            this.cycleText.addClass('hide');
        }
    }

    public resize(){

        let sHeight = $(window).height();
        let headerHeight =  $('.header').height();
        this.content.find('.circle').css('top', (sHeight / 2) + headerHeight);
    }

    public showMe(){

        super.showMe();

        this.resize();
    }

    public hideMe(){

        super.hideMe();       
        clearTimeout(this.progrossTimeout);
        clearTimeout(this.cycleTimeout)
        this.resetCycleText();
        this.cyclingText = false
        this.cycleCount = 0;
    }

}