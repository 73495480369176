class RecordHandler {
    private videoDiv:JQuery;
    private content:JQuery;
    private canvas:any;
    private ctx:any;
    private image:any;
    private width:number = 640;
    private height:number = 480;

    private video: any;
    private delegate:any;
    private dataUri:string;
    private attached:boolean = false;
    private isUsingFlash:boolean = false; 

    constructor(content, del){
        this.content = content;

        this.videoDiv = this.content.find('#video');
        this.delegate = del;
     
        let isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !isIE && !!window.StyleMedia;


        if(isSafari || isIE && !isEdge){
            this.isUsingFlash = true;
             if(isSafari){
                 this.width = window.innerWidth;
                 this.height = window.innerHeight;
             } 
        }

        this.canvas = document.createElement("canvas");
        
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.ctx = this.canvas.getContext("2d");

        this.ctx.clearRect(0, 0, this.width, this.height);
        this.image = this.ctx.getImageData(0, 0, this.width, this.height);
        Webcam.set({
            width: this.width,
            height: this.height,
            dest_width:this.width,
            dest_height:this.height,
            image_format: 'jpeg',
            jpeg_quality: 100,
            flip_horiz:true, 
            force_flash: this.isUsingFlash,
            constraints: { width: { min: this.width }, height: { min: this.height } }
        });

        
        $(window).bind('resize orientationchange', ()=>this.resize());
        this.resize();
    }

    public init(){
        if(this.isUsingFlash){
            this.delegate.hideOverlay();
                this.content.find('.record').css('background-color', 'white')
        }

        Webcam.on( 'live', (e)=> {
            this.delegate.showOverlay();
            if(this.isUsingFlash){
                this.content.find('.record').css('background-color', 'black')
            }

        });

        Webcam.on( 'error', (err)=> {
            console.error(err)
            this.delegate.camError();
        });

        this.attached = true;
        Webcam.attach( '#video' );
        this.resize();
    }

    public resetState(){
        if(this.attached){
            Webcam.reset();
            this.attached = false;
        }
    }

    public stopCapture(){  
        Webcam.snap((data_uri)=>{
            this.dataUri = data_uri;
            this.createInventory();    
        });
    }

    public createInventory(){
        let img = new Image();   
        img.onload = ()=>{
            this.ctx.drawImage(img, 0, 0, this.width, this.height);
            let inv = this.canvas.toDataURL('image/jpeg');
            this.delegate.imageLoaded(inv);
        }
        img.src = this.dataUri;
    }

    public resize(){
        if(!this.isUsingFlash){
            //make the player fullscreen
            var pos = Utils.fitToContainer({
                'containerWidth':window.innerWidth,
                'containerHeight':window.innerHeight,
                'contentWidth':this.width,
                'contentHeight':this.height,
                'vAlign':'top',
                'hAlign':'center',
                'scaleMode':'proportionalOutside'
            });
            this.videoDiv.css(pos);
        } else  {
            let pos = {
                'width':this.width,
                'height':this.height,
                'position':'absolute',
                'margin':'auto',
                'left':'0',
                'right':'0',
                'top':'0',
                'bottom':'0'
            }   

            this.videoDiv.css(pos);
       
        }    
    }
}
