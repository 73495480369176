/// <reference path='../scaffolding/View.ts'/>

class PhaseTwoIntroView extends View {

    private nextBtn:JQuery;

    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent(Main.templates.find('.phaseTwoIntro').clone());
        this.nextBtn = this.content.find('.btnGetStarted');
        this.nextBtn.on('click', ()=>this.changeState());
    }

    public showMe(){

        super.showMe();
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Intro2);
    }

    private changeState(){
        ga('send', 'event', 'phase_two_landing', 'click', 'create_video');
        if(Main.phaseTwoLive){
            page('/info');
        } else {
            page('/photo');
        }
    }

}  