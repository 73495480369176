    /// <reference path='./scaffolding/Main.ts'/>
/// <reference path='./views/IntroView.ts'/>
/// <reference path='./views/InfoView.ts'/>
/// <reference path='./views/VideoView.ts'/>
/// <reference path='./views/PhaseTwoIntroView.ts'/>
/// <reference path='./views/PhotoView.ts'/>
/// <reference path='./components/MobileMenu.ts'/>
/// <reference path='./components/Header.ts'/>
/// <reference path='./components/Footer.ts'/>
/// <reference path='./components/BackgroundController.ts'/>

let main = new Main();
let cont = $("#container").show();
console.log("%cPowered by %cImposium v1.0.6 %cimposium.com","color: white; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#000; padding-top:10px; padding-bottom:4px; padding-left:20px; margin-right:0px;  margin-left:8px; margin-top:4px", "color:#A1B83A; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#000; padding:10px; padding-bottom:4px; padding-left:0px;  padding-right:20px; margin-top:4px", "color:#000; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#A1B83A; padding:10px; padding-bottom:4px; padding-left:20px;  padding-right:20px; margin-top:4px"); 

// var fonts = loadCSS( "https://fonts.googleapis.com/css?family=Open+Sans:400,300,800,700,600" );
// var styles = loadCSS( "/css/styles.css" );

// onloadCSS( styles, function() {
//     setTimeout(()=>{
//         page();
//     }, 50);
// });

let header = new Header(cont, {}, this);
header.showMe();

let background= new BackgroundController(cont, {}, this);
if(Main.mobile){
    cont.addClass('mobile')
    let menu =  new MobileMenu(this.cont, {}, this);
}else{
    let footer = new Footer(cont, {}, this);
    footer.showMe();
}

let introRoute =  (Main.phaseTwoLive) ? '/' : '/phasetwo'; 
let infoRoute =  (Main.phaseTwoLive) ? '/info' : '/photo'; 
let introViewTwo = new PhaseTwoIntroView(cont, {
    'route':introRoute
}, this);

let photoView = new PhotoView(cont, {
    'route':infoRoute
}, this); 

if(!Main.phaseTwoLive){
    let introView = new IntroView(cont, {
      'route':'/'
    }, this);

    let infoView = new InfoView(cont, {
      'route':'/info'
    }, this);  
}

let videoView = new VideoView(cont, {
    'route':'/video',
    'toast':['97cdf90e-4a2f-42d3-9ac5-136a189e8bcf','97cdf90e-4a2f-42d3-9ac5-136a189e8bba'],
    'toast_owner':['97cdf90e-4a2f-42d3-9ac5-136a189e8bcc', '97cdf90e-4a2f-42d3-9ac5-136a189e8bcd', '97cdf90e-4a2f-42d3-9ac5-136a189e8bce'],
    'bestdayever':['d54ac9f7-2714-4ef1-ede7-d4fca4b9033d'],
    'bestdayever_owner':['d54ac9f7-2714-4ef1-ede7-d4fca4b9033d']
}, this);

page();

//facebook
window["fbAsyncInit"] = function() {
    FB.init({
        appId      : CONFIG.facebookAppId,
        xfbml      : true,
        version    : 'v2.6'
    });
};

(function(d, s, id){
   var js, fjs = d.getElementsByTagName(s)[0];
   if (d.getElementById(id)) {return;}
   js = d.createElement(s); js.id = id;
   js.src = "//connect.facebook.net/en_US/sdk.js";
   fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));