
enum BackgroundImages{
    Intro1,
    Intro2,
    Info,
    Thanks,
    Video1,
    Video2
};

class BackgroundController extends Component{
    
    public static LOADED:string = "backgroundsloaded";
    public static CHANGE_BACKGROUND:string = "changebackground";
    public static WHITE:string = "WHITE";

    private imageSources:any = [
        '/img/intro_bg2x.jpg',
        '/img/intro_phasetwo_bg2x.jpg',
        '/img/info_bg2x.jpg',
        '/img/thanks_bg.jpg',
        '/img/loader_bg2x.jpg',
        '/img/info_phasetwo_bg2x.jpg',
    ]

    private images:any = {};
    private imageContainer:any;
    private activeImage:JQuery;
    private whiteBg:JQuery;
    private activeStr:string;
    private bgTimeout:number;

    constructor(container, data, del){

        super(container, data, del);

        this.setContent(Main.templates.find('.bg'));

        this.imageContainer = this.content.find('.imgContainer');
        this.whiteBg =  this.content.find('.white');
        EventBus.addEventListener(BackgroundController.CHANGE_BACKGROUND, (img)=>this.changeBg(img), this);
    }

    private changeBg(i){

        let imgStr = BackgroundImages[i];
        if(i == BackgroundController.WHITE){
            if(this.activeImage){
                this.activeImage.removeClass('active');
            }
            this.activeStr = BackgroundController.WHITE;
            this.whiteBg.addClass('active');
        } else if(imgStr != this.activeStr){

            this.whiteBg.removeClass('active');
            if(this.images[imgStr]){

                if(this.activeImage){
                    this.activeImage.removeClass('active');
                }
                this.activeStr = imgStr;
                this.activeImage = this.images[imgStr];
                this.showBg();

            }else{

                let div = $('<div class="img"></div>');
                let img = new Image();
                img.onload = ()=>{

                    if(this.activeImage){
                        this.activeImage.removeClass('active');
                    }
                    div.css('background-image', 'url(' + this.imageSources[i] +')');
                    this.images[imgStr] = div;
                    this.activeImage = div;
                    this.activeStr = imgStr;
                    this.imageContainer.append(div);
                    this.showBg();
                }
                img.src = this.imageSources[i];
            } 
        }
    }   

    private showBg(){

        clearTimeout(this.bgTimeout);
        this.bgTimeout = setTimeout(()=>{
            this.activeImage.addClass('active');
        }, 10);
    }
}