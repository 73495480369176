/// <reference path='../scaffolding/view.ts'/>
/// <reference path='../components/Editor.ts'/>
/// <reference path='../components/Record.ts'/>
/// <reference path='../components/Photo.ts'/>

class PhotoView extends View {

    public static SHOW_PHOTO:string = "showphoto";
    public static SHOW_EDITOR:string = "showeditor";
    public static SHOW_RECORD:string = "showrecord";
    public static CHECK_DATA:string = "checkdata";

    private photo:Photo;
    private editor:Editor;
    private record:Record;
   

    private loadOverlay:JQuery;

    public firstName:string;
    public editorShowing:boolean =  false;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);

        this.setContent(Main.templates.find('.photoWrap').clone());

        EventBus.addEventListener(PhotoView.SHOW_EDITOR, ()=>this.showEditor(), this);
        EventBus.addEventListener(PhotoView.SHOW_RECORD, ()=>this.showRecord(), this)
        EventBus.addEventListener(PhotoView.SHOW_PHOTO, ()=>this.showPhoto(), this)
        EventBus.addEventListener(PhotoView.CHECK_DATA, (data)=>this.checkData(data), this)

        this.photo = new Photo(this.content, {}, this);
        this.editor = new Editor(this.content, {}, this);
        this.record = new Record(this.content, {}, this);

        this.loadOverlay = this.content.find('.loadingOverlay');
          
    }    

    public setRoutes(){

        page(this.data.route, ()=>{
            EventBus.dispatch(View.HIDE_ACTIVE);
            this.showMe();    
        });

        page('/owner', ()=>{
            Main.isOwner = true;
            EventBus.dispatch(View.HIDE_ACTIVE);
            this.showMe();    
        });
        
    }

    public showMe(){
        super.showMe();
        this.showPhoto();
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Video2);
    }

    private hideComponents(){
        this.photo.hideMe();
        this.record.hideMe();
        this.editor.hideMe();
    }

    private showEditor(){
        if(!this.editorShowing){
            this.hideComponents();
            this.editor.showMe();
            this.editorShowing = true;
            this.hideOverlay();
        }
    }

    private checkData(data){
        if(data.type == "record"){
            this.showEditor();
        }
        this.editorShowing = false;
        this.editor.loadImage(data);
        this.showOverlay();
    }

    private showRecord(){
       this.hideComponents();
       this.record.showMe();
    }

    private showPhoto(){
        this.hideComponents();
        this.photo.showMe(); 
        this.hideOverlay();     
    }

    public showOverlay(){
        this.loadOverlay.removeClass('hide')
    }

    public hideOverlay(){
        this.loadOverlay.addClass('hide')
    }

    public hideMe(){
        super.hideMe();
        this.showPhoto();
    }
}
