/// <reference path='../scaffolding/Component.ts'/>

class Thanks extends Component {

    private btnRewatch:JQuery;
    private btnCreate:JQuery;
    private phase:String = "phase_two_thanks";
   
    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
       
        this.setContent(Main.templates.find('.thanksWrap').clone());

        this.btnRewatch = this.content.find('.btnRewatch').on('click', ()=>this.rewatch());
        this.btnCreate = this.content.find('.btnCreate').on('click', ()=>this.create());
        EventBus.addEventListener(DynamicVideoView.PHASE_SET, ()=>this.setPhase(), this);

        this.content.find('.facebook').bind('click', (e)=>this.delegate.shareFacebook(e));
        this.content.find('.twitter').bind('click', (e)=>this.delegate.shareTwitter(e));
        this.content.find('.mailLink').bind('click', (e)=>this.delegate.shareEmail(e));
        this.content.find('.download').bind('click', (e)=>this.delegate.shareDownload(e));

        EventBus.addEventListener(DynamicVideoView.PHASE_SET, ()=>this.setPhase(), this);
        this.setPhase();
        this.hideDelay = 500;
    }

    private setPhase(){
        if(VideoView.PHASE == VideoView.PHASE_ONE){
            this.btnCreate.html("Edit/create another");
            this.phase = "phase_one_thanks";
        }
    }


    private rewatch(){

        this.hideMe();

        ga('send', 'event', this.phase, 'click', 'rewatch');
        EventBus.dispatch(VideoPlayer.RESET);
        EventBus.dispatch(VideoPlayer.SHOW);
        EventBus.dispatch(VideoPlayer.PLAY);
    }

    public showMe(){

        super.showMe();

        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Thanks);
        
    }

    private create(){

        VideoView.VIN = "";
        Main.isOwner = false;
        VideoView.VIN = "";
        let route = (!Main.phaseTwoLive && VideoView.PHASE == VideoView.PHASE_TWO) ? '/photo' : '/info';
        ga('send', 'event', this.phase, 'click', 'create_more');
        page(route);
    }
}
