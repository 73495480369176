/// <reference path='../scaffolding/View.ts'/>

class IntroView extends View {

   constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent($("#container").find('.introView'));

        this.content.find('.btnGetStarted').on('click', ()=>{
            ga('send', 'event', 'phase_one_landing', 'click', 'create_video');
            page('/info');
        });

        this.hideDelay = 500;
    }

    public showMe(){

        super.showMe();
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Intro1);
    }
}