/// <reference path='./Utils.ts'/>

/**
 * Main static class, holds all global config variables and functions for all views and components.
 */
class Main {

    /**
     * Browser specific css style declaration to use for transforming objects.
     */
    static xform:string;

    /**
     * Browser specific css style declaration to use for transitioning objects.
     */
    static transition:string;

    static templates:JQuery;
    
    static sWidth:number;

    static sHeight:number;

    static mobile:boolean = false;

    static phaseTwoLive:boolean = true;

    static isOwner:boolean = false;
    
    static isIE:boolean = false;
    
    /**
     * Creates a new Main instance, sets the templates for all views and components to use, creates a global socket connection, and gets the saved state from a cookie.
     *
     * @param config    Configuration variables passed in from config.php.
     */
    constructor() {

        //set the php config variables
        this.setPrefixes();

        Main.templates = $("#templates");

        let isMobile = Utils.detectMobileOS();
        Main.mobile = (isMobile.os !== "unknown");
        if(isMobile.os =="iOS"){
            $('#container').addClass('iphone');
        }


        Main.isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !Main.isIE && !!window.StyleMedia;

        if(isEdge){
            $('#container').addClass('edge');
        }

        $(window).bind('resize orientationchange', ()=>this.resize());
        this.resize();

        let onEvt = (Main.mobile) ? 'touchstart' : 'mouseenter';
        let offEvt = (Main.mobile) ? 'touchend touchleave touchcancel' : 'mouseleave mouseout';

        $(document).on(onEvt, '.interactive', function(e){
            $(this).addClass('hover');
        });

        $(document).on(offEvt, '.interactive', function(e) {
            $(this).removeClass('hover');
        });

        rivets.formatters["dateTime"] = function(val){

            let d = new Date(val*1000);
            let hours = d.getHours();
            let ampm = 'AM';
            if(hours >=12){
                ampm = 'PM';
            }
            if(hours > 12){
                hours -= 12
            }
            let dformat = [Utils.padLeft((d.getMonth()+1)),
                           Utils.padLeft(d.getDate()),
                           d.getFullYear()].join('/') +' ' +
                          [Utils.padLeft(hours),
                           Utils.padLeft(d.getMinutes())].join(':');

            return dformat + ' ' + ampm;
        }

        rivets.formatters["prettyTime"] = function(val){
            let min = Math.floor(val / 60);
            let sec = Math.floor(val % 60);
            let minStr= (min) ? min + ':' : '0:';
            let secStr = (sec > 9) ? sec : '0' + sec;

            return minStr + secStr;
        }
    }

    private resize() {

        Main.sWidth = window.innerWidth;
        Main.sHeight = window.innerHeight;
    }

    /**
     * Sets Main.xform and Main.transition by detecting which prefix exists on the current browser.
     */
    private setPrefixes():void {

        var view = document.createElement('div');
        var prefixes = ['webkit', 'Moz', 'O', 'ms'];
        for (var i = 0; i < prefixes.length; i++) {
            var prefix = prefixes[i];
            var e = prefix + 'Transform';
            var t = prefix + 'Transition';

            if (typeof view.style[e] !== 'undefined') {
                Main.xform = e;
                Main.transition = t;
            }
        }
        view = null;
    }
}


