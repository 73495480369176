/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='./CanvasHandler.ts'/>

class Editor extends Component {
    private canvas:JQuery;
    private backBtn:JQuery;
    private photoBtn:JQuery;
    private interactions:JQuery;
    private scrubber:JQuery;
    private progress:JQuery;
    private faceError:JQuery;
    private canvasHandler:CanvasHandler;
    private maxZoomSize:number = 3;

    //rotate vars
    private isRotating:boolean = false;
    private rotateTimeout:number;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.editContainer').clone());

        this.canvas = this.content.find('.imageCanvas');

        this.backBtn = this.content.find('.btnEditBack').on('click', ()=>this.goBack());
        this.photoBtn = this.content.find('.btnUsePhoto').on('click', ()=>this.genVideo());
        this.interactions = this.content.find('.interactions');
        this.faceError = this.content.find('.faceError');
        this.scrubber = this.content.find('.scrubber').on('mousedown', (e)=>this.startDrag(e));
        this.progress = this.content.find('.progress');
        this.content.find('.rotate').on('mousedown', (e)=>this.rotateStart(e));
        this.content.find('.rotate').on('mouseup', ()=>this.rotateStop());
        this.canvasHandler = new CanvasHandler(this.content, {}, this);


    }

    public loadImage(data){
        if(data.type == "record"){
            this.canvasHandler.handleRecord(data);
        }else if(data.type =="upload"){
            this.canvasHandler.handleUpload(data.content);
        }else if(data.type == "url"){
            this.canvasHandler.handleURL(data.content);
        }
    }

    private genVideo(){
        this.delegate.showOverlay();
        ga('send', 'event', 'phase_two_adjust', 'click', 'use_photo');
        EventBus.dispatch(VideoView.GENERATE_VIDEO, this.getInv());
    }
    
    public getInv(){
        let img = this.canvasHandler.imageToUpload();
        let blob:any = Utils.dataURItoBlob(img);
        let data = {
            inventory:{
                "face_shot":  blob,
                "first_name":this.delegate.firstName,
                "is_owner":Main.isOwner
            },
            phase:VideoView.PHASE_TWO,
        }
        return data;
    }

    private goBack(){

        ga('send', 'event', 'phase_two_adjust', 'click', 'go_back');
        EventBus.dispatch(PhotoView.SHOW_PHOTO);
    }

    private startDrag(e){
        $(window).on('mousemove', (e)=>this.moveDragger(e));
        $(window).one('mouseup touchend', (e)=>this.stopDragging(e));
    }

    private stopDragging(e){
         $(window).off('mousemove');
    }

    private moveDragger(e){
        let bar = this.content.find('.track');
        let xpos = ((e.clientX -bar.offset().left)-this.scrubber.width()/2);
        let perc = xpos/bar.width();
        if(perc < 0){
            perc = 0 ;
        } else if(perc > 0.99) {
            perc = 0.99;
        }
        this.scrubber.css('left', perc*100+"%");
        this.progress.css('width', perc*100+"%");
        let sizePerc =  this.maxZoomSize*perc;
        this.canvasHandler.resizeSprite(sizePerc);
    }

    public repositionScrubber(deg){
        let track = this.content.find('.track');
        let zoomPerc =  (track.width()*this.maxZoomSize)/(deg*100);
        let position =  track.width()/zoomPerc;
        this.scrubber.css('left', position+"%");
        this.progress.css('width', position+"%");
    }

    public showBtns(){
        this.backBtn.show();
        this.photoBtn.show();
        if(!this.data.isMobile){
            this.interactions.show();
        }
    }

    private rotateStart(e){
        clearTimeout(this.rotateTimeout);
        this.isRotating = true;
        this.rotateImage(e.target.className);
    }

    private rotateImage(directon){
        if(this.isRotating){
            let deg = 0.1;
            if(directon == "right"){
                deg = -0.1;
            }
            this.canvasHandler.rotateSprite(deg);
            clearTimeout(this.rotateTimeout);
            this.rotateTimeout = setTimeout(()=>{
                this.rotateImage(directon)
            }, 50);
        } else {
            clearTimeout(this.rotateTimeout);
        }
    }

    private rotateStop(){
        this.isRotating = false;
    }

    public hideMe(){
        super.hideMe();
        this.canvasHandler.clearCanvas();
        this.backBtn.hide();
        this.photoBtn.hide();
        this.faceError.hide();
        this.delegate.hideOverlay();

        if(!this.data.isMobile){
            this.interactions.hide();
        }
    }
}
