class CanvasHandler extends Component{

    //config
    private eyeY:number = 140;
    private eyeWidth:number = 120;
    private width:number = 290;
    private height:number = 352;
    private bigWidth:number = 870;
    private bigHeight:number = 1056;
    private mobileWidth:number = 174;
    private mobileHeight:number = 211;
    private debug:boolean = false;
    private detectFaces:boolean;
    private adult:any;

    //pixi
    private mainStage:PIXI.Container;
    private bigStage:PIXI.Container;
    private mainRenderer:PIXI.CanvasRenderer;
    private bigRenderer:PIXI.CanvasRenderer;
    private hammer:any;
    private sprite:any;
    private raf:number;
    
    //input image    
    private fileReader:FileReader;
    private orientation:number;
    private resizedWidth:number;
    private resizedHeight:number;
    private apiImg:any;
    private canvasScale:number;
    private posScale:number = 1;
    private recordBoundingBox:Box;

    //dragging
    private dragging:boolean = false;
    private dragger:any;
    private dragData:any;

    //scaling
    private pinching:boolean = false;
    private rotating:boolean = false;
    private initRotate:number = 0;
    private maxRotation:number = 360;
    private minRotation:number = 0;

    private minScale:number = 0.1;
    private maxScale:number = 5;
    private scaleOffset:number;

    private barScale:number;

    constructor(container, data, delegate){

        super(container, data, delegate);

        //change scale for mobile and add touch event listeners
        if(Main.mobile){
            this.posScale = this.mobileWidth / this.width;
            this.width = this.mobileWidth;
            this.height = this.mobileHeight;
        }

        this.canvasScale = this.bigWidth / this.width;

        //create the pixi stage for manipulating the image
        this.mainStage = new PIXI.Container();
        this.mainStage.interactive = true;
        this.mainRenderer = new PIXI.CanvasRenderer(this.width, this.height, {'transparent':true});
        this.container.find('.face').prepend(this.mainRenderer.view);

        //create the pixi stage for creating the large image
        this.bigStage = new PIXI.Container();
        this.bigStage.interactive = true;
        this.bigRenderer = new PIXI.CanvasRenderer(870, 1056, {'transparent':true});

        //create the fileReader for getting the dataURL from the uploaded image
        this.fileReader = new FileReader();
        this.fileReader.onload = (e)=>this.processImage(e);

        if(Main.mobile){
            this.handleTouch();
        }else{
            this.handleDesktop();
        }
    }

    public handleRecord(data){

        this.detectFaces = true;
        this.recordBoundingBox = data.box;
        let d = {'target':{'result':data.content}};
        this.processImage(d);
    }

    public handleURL(img){

        this.detectFaces = true;
        this.recordBoundingBox = null;

        let d = {'target':{'result':img}};
        this.processImage(d);
    }   

    public handleUpload(img){

        this.detectFaces = true;
        this.recordBoundingBox = null;
        //first get the EXIF data and figure out if we have to rotate the image
        EXIF.getData(img, ()=>{          
            this.orientation = EXIF.getAllTags(img).Orientation;
            this.fileReader.readAsDataURL(img);
        });
    }

    public resizeSprite(scale){
        this.sprite.scale.x = scale;
        this.sprite.scale.y = scale;
    }

    public imageToUpload(){

        let texture = PIXI.Texture.fromImage(this.apiImg);
        let sprite = new PIXI.Sprite(texture);

        sprite.anchor.x = this.sprite.anchor.x;
        sprite.anchor.y = this.sprite.anchor.y;

        sprite.position.x = this.sprite.position.x * this.canvasScale;
        sprite.position.y = this.sprite.position.y * this.canvasScale;

        sprite.scale.x =  this.sprite.scale.x * this.canvasScale;
        sprite.scale.y =  this.sprite.scale.y * this.canvasScale;

        sprite.rotation = this.sprite.rotation;
        
        this.bigStage.addChild(sprite);

        this.bigRenderer.render(this.bigStage);

        let url = this.bigRenderer.view.toDataURL();
        this.bigStage.removeChild(sprite);

        if(this.debug){
            this.bigRenderer.view.id = "bigRenderer";
            $("#container").append(this.bigRenderer.view);
        }

        return url;
    }

    public clearCanvas(){
       for (var i = this.mainStage.children.length - 1; i >= 0; i--) {    this.mainStage.removeChild(this.mainStage.children[i]);};
       for (var i = this.bigStage.children.length - 1; i >= 0; i--) {    this.bigStage.removeChild(this.bigStage.children[i]);};

       this.mainRenderer.render(this.mainStage);
       cancelAnimationFrame(this.raf);
    }

    private animate() {
        this.raf = requestAnimationFrame( ()=>this.animate() );
        this.mainRenderer.render(this.mainStage);
    }

    private dragStart(e){

        this.dragData = {
            'x':e.clientX,
            'y':e.clientY
        }

        this.dragging = true;

        $(this.mainRenderer.view).on('mousemove', (e)=>this.dragMove(e));
        $(this.mainRenderer.view).on('mouseup mouseupoutside mouseleave', (e)=>this.dragEnd(e));
    }

    private dragMove(e){

        if(!this.pinching){

            if(this.dragging){

                let offX = e.clientX - this.dragData.x;
                let offY = e.clientY - this.dragData.y;

                this.sprite.position.x += offX;
                this.sprite.position.y += offY;

                this.dragData = {
                    'x':e.clientX,
                    'y':e.clientY
                }
            }    
        }
    }   
    
    private dragEnd(e){

        this.dragging = false;
        this.dragData = null;    

        $(this.mainRenderer.view).off('mousemove');
        $(this.mainRenderer.view).off('mouseup');
    }

    private panStart(e){

        this.dragData = {
            'x':e.center.x,
            'y':e.center.y
        }

        this.dragging = true;
    }

    private panMove(e){

        if(!this.pinching){

            if(this.dragging){

                let offX = e.center.x - this.dragData.x;
                let offY = e.center.y - this.dragData.y;

                this.sprite.position.x += offX;
                this.sprite.position.y += offY;

                this.dragData = {
                    'x':e.center.x,
                    'y':e.center.y
                }
            }    
        }
    }   
    
    private panEnd(e){

        this.dragging = false;
        this.dragData = null;    
    }

    private pinchStart(e){

        e.preventDefault();

        this.scaleOffset = this.sprite.scale.x;
        this.pinching = true;
    }
    
    private pinchEnd(e){

        e.preventDefault();

        this.pinching = false;
    }

    private pinchZoom(e){

        e.preventDefault();
        let scale = this.scaleOffset * e.scale; 
        if(scale < this.minScale) scale = this.minScale;
        if(scale > this.maxScale) scale = this.maxScale;

        this.sprite.scale.x = scale;
        this.sprite.scale.y = scale;
    }

    private scrollZoom(e){
           let zoomChange = -0.1;
           let delta = (e.originalEvent.wheelDelta)  ? e.originalEvent.wheelDelta  : e.originalEvent.deltaY;
         
           if (delta > 0) {
               zoomChange = 0.1;
           }    
           
           let nScale = this.barScale + zoomChange;
           this.barScale = nScale;

           if(this.barScale < 0.005 ){
               this.barScale = 0.005;
           } else if(this.barScale>2.9){
               this.barScale = 2.9;
           }

           this.sprite.scale.x =  this.barScale;
           this.sprite.scale.y =  this.barScale;

           this.delegate.repositionScrubber(this.barScale);
           e.preventDefault();
    }

    private handleTouch(){
        var options = {
          preventDefault: true
        };
        let targ = this.mainRenderer.view;
        this.hammer = new Hammer.Manager(targ);
        let rotate = new Hammer.Rotate();
        let pinch = new Hammer.Pinch();
        pinch.recognizeWith(rotate);      
        let pan = new Hammer.Pan();
        this.hammer.add([pinch, pan, rotate]);

        this.hammer.on("panstart", (e)=>this.panStart(e));
        this.hammer.on("panmove", (e)=>this.panMove(e));
        this.hammer.on("panend", (e)=>this.panEnd(e));

        this.hammer.on('rotatestart', (e)=>this.rotateStart(e));
        this.hammer.on('rotatemove', (e)=>this.rotateMove(e));
        this.hammer.on('rotateend', (e)=>this.rotateStop(e));

        this.hammer.get('pinch').set({ enable: true });
        this.hammer.on("pinch", (e)=>this.pinchZoom(e));
        this.hammer.on("pinchstart", (e)=>this.pinchStart(e));
        this.hammer.on("pinchend", (e)=>this.pinchEnd(e));
   

    }

    private handleDesktop(){

        $(this.mainRenderer.view).on('mousedown', (e)=>this.dragStart(e));
        $(this.mainRenderer.view).on('wheel', (e)=>this.scrollZoom(e));
    }

    private processImage(e){

        const img = new Image();
        // img.crossOrigin = 'Anonymous';

        img.onload= () =>{
            //position vars
            const w = (this.orientation <= 4 || !this.orientation) ? img.naturalWidth : img.naturalHeight;
            const h = (this.orientation <= 4 || !this.orientation) ? img.naturalHeight : img.naturalWidth;
            let scaleX = 1;
            let scaleY = 1;
            let x = -w/2;
            let y = -h/2;
            let rotation = 0;

            //create the canvas for rotating the positining the large version of the image
            const canvas = document.createElement('canvas');
            canvas.id = "orientationCanvas";
            canvas.width = w;
            canvas.height = h;
            const context = canvas.getContext('2d');
            context.translate(w/2, h/2);

            //based off of the exif data, rotate, position and scale the image. 
            switch(this.orientation){

                case 2: 
                    scaleX = -1;
                    scaleY - 1;
                    break;
                case 3: 
                    rotation = 180;
                    break;
                case 4: 
                    rotation = 180;
                    scaleX = -1;
                    scaleY - 1;
                    break;
                case 5: 
                    rotation = 90;
                    x = -h/2;
                    y = -w/2;
                    scaleX = 1;
                    scaleY = -1;
                    break;
                case 6: 
                    rotation = 90;
                    x = -h/2;
                    y = -w/2;
                    break;
                case 7: 
                    rotation = -90;
                    x = -h/2;
                    y = -w/2;
                    scaleX = 1;
                    scaleY = -1;
                    break;
                case 8: 
                    rotation = -90;
                    x = -h/2;
                    y = -w/2;
                    break;
            }

            //position the image
            context.rotate(Utils.degreesToRadians(rotation));
            context.scale(scaleX, scaleY);
            context.drawImage(img, x, y);

            //create a resized version of the image to send to the API
            this.resizedWidth = w;
            this.resizedHeight = h;
            let r = w / h;
            let cWidth = (w > h) ? 1920 : 1080;
            this.apiImg = canvas.toDataURL();
            let resizeCanvas;

            //if the image is larger than the target "small" width, scale it
            if(w > cWidth){
                let cHeight = cWidth / r;
                resizeCanvas = document.createElement('canvas');
                resizeCanvas.id = "resizeCanvas";
                let resizeContext = resizeCanvas.getContext('2d');
                resizeCanvas.width = cWidth;
                resizeCanvas.height = cHeight;
                resizeContext.drawImage(canvas, 0,0, cWidth, cHeight);    
                this.resizedWidth = cWidth;
                this.resizedHeight = cHeight;
                this.apiImg = resizeCanvas.toDataURL();
            }

            //if in debug mode, put the canvases on the stage
            if(this.debug){
                $("#container").append(canvas).append(resizeCanvas);
            }

            this.moderateImage(this.apiImg)
        }
        //load the image from the data URL
        img.src = e.target.result;
    }

    private moderateImage(fileData){

        let blob = Utils.dataURItoBlob(fileData); //convert the data URI to a blob so it works with their API

        $.ajax({
            url: "https://westus.api.cognitive.microsoft.com/vision/v1.0/analyze/?visualFeatures=Adult",
            type: "POST",
            data: blob,
            processData: false, //IMPORTANT
            contentType: false, //IMPORTANT
            success: (data)=>{
                this.adult = data.adult.isAdultContent || data.adult.isRacyContent;
                if(!this.adult){
                    if(this.detectFaces){
                        this.findFaces(this.apiImg);
                    }else{
                        this.addFaceToCanvas(null, this.apiImg);
                    }
                } else {
                    EventBus.dispatch(Photo.PHOTO_ERROR);
                }
            },
            error: function(e){
                console.error(e);
                EventBus.dispatch(Photo.PHOTO_ERROR);
            },
            beforeSend: function (r){ 
                r.setRequestHeader("Content-Type","application/octet-stream");
                r.setRequestHeader("Ocp-Apim-Subscription-Key", "76ff50c2958e44ef9adacc2b9612c031");
            }
        });
    }

    //converts the data URI to a blob, and uploads it to the Face API for decection
    private findFaces(img){

        let blob = Utils.dataURItoBlob(img);
        $.ajax({
            url: "https://westus.api.cognitive.microsoft.com/face/v1.0/detect?returnFaceLandmarks=true",
            type: "POST",
            data: blob,
            processData: false,
            contentType: false,
            success: (data)=>{
                this.addFaceToCanvas(data[0], img);
            },
            error: function(e){
                console.error(e);
                this.addFaceToCanvas(null, img);
            },
            beforeSend: function (r){ 
                r.setRequestHeader("Content-Type","application/octet-stream");
                r.setRequestHeader("Ocp-Apim-Subscription-Key", "1808ff2d41cc47c69b6f748ac8b58297");
            }
        });
    }

    //puts the sprite on the canvas for user interaction
    private addFaceToCanvas(faceData, imgData){
        
        EventBus.dispatch(Photo.PHOTO_PASSED);

        //create a new sprite
        let texture = PIXI.Texture.fromImage(imgData);
        this.sprite = new PIXI.Sprite(texture);

        //set the initial position of the sprite, this function will return defaults if there's no face data found on the api
        let initialPosition = this.getInitialPosition(faceData);
        this.sprite.anchor.x = initialPosition.anchor.x;
        this.sprite.anchor.y = initialPosition.anchor.y;
        this.sprite.position.x = initialPosition.position.x;
        this.sprite.position.y = initialPosition.position.y;
        
        if(initialPosition.scale > 2.9){
            initialPosition.scale = 2.9;
        }

        this.sprite.scale.x =  initialPosition.scale;
        this.sprite.scale.y =  initialPosition.scale;

        // this.sprite.rotation = initialPosition.rotation;
       
        this.barScale = initialPosition.scale;
        this.delegate.repositionScrubber(initialPosition.scale);
     
        this.sprite.interactive = true;
        this.sprite.buttonMode = true;
        
        this.mainStage.addChild(this.sprite);
        this.delegate.showBtns();

        //render
        this.animate();
    }

    private rotateStart(e){
        this.rotating = true;
        this.initRotate =  e.rotation;
    }

    private rotateMove(e){
        let currentRotation = Utils.radiansToDegrees(e.rotation);
        let pastRotation = Utils.radiansToDegrees(this.initRotate)
        let rotateDiff = currentRotation-pastRotation;
        if(currentRotation<pastRotation){
            rotateDiff = pastRotation-currentRotation;
            let rotation = Math.abs(rotateDiff)/2000
            if(rotation < 1){
                this.sprite.rotation -= rotation;
            }
        } else {
            let rotation = Math.abs(rotateDiff)/2000
            if(rotation < 1){
                this.sprite.rotation += rotation;
            }
        }
        this.initRotate = e.rotation;
    }

    private rotateStop(e){
        this.rotating = false;
        this.initRotate = 0;
    }

    public rotateSprite(deg){
        this.sprite.rotation += deg;
    }

    //returns the initial position of the image if there was a face found in it, or not
    private getInitialPosition(faceData:FaceAPIData):PositionData{

        let data:PositionData = {
            'anchor':{
                'x':0,
                'y':0
            },
            'rotation':0,
            'position':{
                'x':0,
                'y':0
            },
            'scale':1
        };

        //if we have facial features, use them to position it
        if(faceData){
            let eyeLeft = faceData.faceLandmarks.pupilLeft;
            let eyeRight = faceData.faceLandmarks.pupilRight; 

            //set the anchor point
            let eyeCenter = Utils.getMidPoint(eyeLeft, eyeRight);
            data.anchor.x = eyeCenter.x / this.resizedWidth;
            data.anchor.y = eyeCenter.y / this.resizedHeight;

            //rotate based off of the angle between the pupils
            let eyeOffset = -Utils.calcAngle(eyeLeft, eyeRight) + 90;  
            data.rotation = Utils.degreesToRadians(eyeOffset);

            //position the anchor on the eye line, horizontally centered
            data.position.x = this.width / 2;
            data.position.y = this.eyeY * this.posScale;

            //get the distance between the pupils, and scale
            let eyeDistance = Utils.lineDistance(eyeLeft, eyeRight);
            let scale = this.eyeWidth / eyeDistance * this.posScale;
            data.scale = scale;

        //if this was a recording, use the bounding box
        }else if (this.recordBoundingBox){

            data.anchor.x = 0.5;
            data.anchor.y = 0.5;
            data.scale = 0.68;
            data.position.x = this.width / 2;
            data.position.y = this.height / 2;
            
        //if we don't have facial features, scale it to fit   
        }else{
            // data.scale = (this.resizedWidth < this.resizedHeight) ? this.width / this.resizedWidth : this.height / this.resizedHeight;
            data.scale = 1;
            data.anchor.x = 0.5;
            data.anchor.y = 0.5;
            data.position.x = this.width / 2;
            data.position.y = this.height / 2;
        }

        return data;
    }
}

interface PositionData{
    scale:number;
    position:Vector2;
    rotation:number;
    anchor:Vector2;
}

interface Box{
    x:number;
    y:number;
    width:number;
    height:number;
}