/// <reference path='../scaffolding/DynamicVideoView.ts'/>
/// <reference path='../components/Loader.ts'/>
/// <reference path='../components/Success.ts'/>
/// <reference path='../components/Thanks.ts'/>
/// <reference path='../components/VideoPlayer.ts'/>

class VideoView extends DynamicVideoView{

    public static GENERATE_VIDEO:string = "generatevideo";
    public static REWATCH_VIDEO:string = "rewatchvideo";
    public static PHASE:string;
    public static PHASE_ONE:string = "toast";
    public static PHASE_TWO:string = "bestdayever";
    public static VIN:string;
    private successTimeout:number;

    private loader:Loader;
    private success:Success;
    private videoPlayer:VideoPlayer;
    private thanks:Thanks;
    private viewShowing:string;
    private shareUrl:string;
    public emailsub:string;
    public emailbod:string;
    private videoUrl:string = "";

    constructor(container, data, id){


        super(container, data, id);

        this.setContent(Main.templates.find('.videoView').clone());

        EventBus.addEventListener(VideoView.GENERATE_VIDEO, (data)=>this.generateVideo(data), this);
        EventBus.addEventListener(VideoView.REWATCH_VIDEO, ()=>this.rewatch(), this)

        this.loader = new Loader(this.content, {}, this);
        this.success = new Success(this.content, {}, this);
        this.videoPlayer = new VideoPlayer(this.content, {}, this);
        this.thanks = new Thanks(this.content, {}, this);
        this.videoPlayer.on(VideoPlayer.VIDEO_COMPLETE, ()=>this.videoEnded(), this);

        $(window).bind('resize orientationchange', ()=>this.resize());
        this.resize();
    }

    public shareFacebook(e){

        e.preventDefault();

        var obj = {
            method: 'share',
            href: this.shareUrl
        };

        FB["ui"](obj);

        let phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'facebook');
    }

    public shareDownload(e){

        e.preventDefault();

        let name = (VideoView.PHASE == VideoView.PHASE_ONE) ? 'A_Toast_From_The_Barkleys.mp4' : 'Best_Day_Ever.mp4';
        let form = $('<form action="/download" method="post">' + 
                    '<input type="text" name = "url" value = "' + this.videoUrl + '">' + 
                    '<input type="text" name = "filename" value = "' + name + '">' + 
                 '</form>');

        $("#templates").append(form);

        form.submit();
        form.remove();
    }

    public shareTwitter(e){

        e.preventDefault();

        let index = (Main.isOwner) ? VideoView.PHASE + "_owner" : VideoView.PHASE; 
        let copy = CONFIG.twitter[index];
        
        var tweetUrl = 'https://twitter.com/intent/tweet';
        tweetUrl += '?url=' + encodeURIComponent(this.shareUrl);
        tweetUrl += '&text=' + copy;

        Utils.openWindow(tweetUrl, 550, 480);

        let phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'twitter');
    }

    public shareEmail(e){

        e.preventDefault();

        window.location.href = "mailto:?subject=" + encodeURI(this.emailsub) + "&body=" + encodeURI(this.emailbod);

        let phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'email');       
    }

    private videoEnded(){

        if(Main.mobile){
            this.videoPlayer.setThanks();
        }else{
            this.videoPlayer.hideMe();
            this.thanks.showMe();
            this.viewShowing = "thanks";
        }
    }

    public makeCategory(){
        let phase = "phase_one_";
        if(VideoView.PHASE == VideoView.PHASE_TWO){
            phase = "phase_two_";
        }

        let suffix = "video";
        if(this.viewShowing == 'thanks'){
            suffix = 'thanks'
        }

        phase += suffix;
        return phase;
    }

    public loadVideo(){

        super.loadVideo();
        this.loader.reset();

        if(this.deeplink){
            this.loader.showMe();
            this.loader.updatePercentage(0.1);
        }
    }

    private setShare(src){
        this.shareUrl = CONFIG.baseUrl + '/video/' + VideoView.PHASE + '/' + this.experienceId;

        if(Main.isOwner){
            this.shareUrl +="/1";
        }
        //email
        let index = (Main.isOwner) ? VideoView.PHASE + "_owner" : VideoView.PHASE;

        let sub = CONFIG.title[VideoView.PHASE];
        let bod = CONFIG.email[index];
        bod = bod.replace("[url]", this.shareUrl);
        this.emailbod = bod;
        this.emailsub = sub;
    }

    public experienceCreated(e){

        super.experienceCreated(e);

        this.success.showMe();
        clearTimeout(this.successTimeout);
        this.successTimeout = setTimeout(()=>{

            this.success.hideMe();
            this.loader.showMe();
        }, 2000);
    }

    public generateVideo(data){

        VideoView.PHASE = data.phase;
        this.storyId = this.getStoryId();
        this.data.inv = data.inventory;

        this.createExperience();
    }

    public getInventory(){
        if(VideoView.VIN && VideoView.PHASE == VideoView.PHASE_ONE){
            return {
                'vin':VideoView.VIN
            }
        } else {
            if(VideoView.VIN){
                 this.data.inv['vin'] = VideoView.VIN;
            }
            return this.data.inv;
        }
    }

    public gotScene(d){
        setTimeout(()=>{
            if(this.onStage && d.experience_id == this.experienceId){
                this.videoPlayer.showMe();
                this.viewShowing = 'video'

                let src;
                if(d.videoUrls.mp4_1080){
                    src = d.videoUrls.mp4_1080
                } else if(d.videoUrls.mp4_720){
                    src = d.videoUrls.mp4_720
                }
                if(Main.mobile){
                    src = d.videoUrls.mp4_480;
                }

                this.videoUrl = src;

                // if(document.createElement('video').canPlayType('application/vnd.apple.mpegURL')){
                //     src = d.videoUrls.m3u8;
                // }

                this.setShare(src);
                this.videoPlayer.loadVideo(src);
            }
        },1000)
    }
    
    public onStatusUpdate(e){
        if(this.onStage && e.id == this.experienceId){

            let perc = Utils.getPercentage(e.msg);
            if(perc == 0.16666666666666666){
                perc = 0;
            }
            this.loader.updatePercentage(perc);

            // if(perc == 1){
            //     this.loader.hideMe();
            // }
        }
    }

    public noVideo(){

        page('/');
    }

    private rewatch(){
        this.loadVideo();
    }

    public resize(){

    }

    public showMe(){

        super.showMe();

        let bg = (VideoView.PHASE == VideoView.PHASE_TWO) ? BackgroundImages.Video2 : BackgroundImages.Video1;
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, bg);

    }

    public hideMe(){

        super.hideMe();

        clearTimeout(this.successTimeout);
        this.success.hideMe();
        this.videoPlayer.hideMe();
        this.loader.hideMe();
        this.thanks.hideMe();
    }
};