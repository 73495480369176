class CountDown {
     private del:any;
     private countDownTimer:number;
     private count:number =  1;
     private content:JQuery;
     private one:JQuery;
     private two:JQuery;
     private three:JQuery;
     private number:JQuery;

     constructor(content, del){
        this.content = content;
        this.del = del;
        this.one = this.content.find('.one');
        this.two = this.content.find('.two');
        this.three = this.content.find('.three');
        this.number = this.content.find('.number');
     }

     public show(){
         this.content.show();
         clearInterval(this.countDownTimer);
         this.countDownTimer = setInterval(()=>{this.countDown()},1000);
     }

     private countDown(){
         this.number.removeClass('highlighted');
         if(this.count == 1){
             this.one.addClass('highlighted');
         } else if(this.count == 2){
             this.two.addClass('highlighted');
         } else if(this.count == 3){
             this.three.addClass('highlighted');
             clearInterval(this.countDownTimer);
             setTimeout(()=>{
                 this.del.takePhoto();
                 this.hide();
             },500);

         }
         this.count++;
     }

     public hide(){
         this.count = 1;
         clearInterval(this.countDownTimer);
         this.content.hide();
         this.number.removeClass('highlighted');
     }
}
