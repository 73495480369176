/// <reference path='../scaffolding/component.ts'/>

class Photo extends Component {
    private uploadBtn:JQuery;
    private recordBtn:JQuery;
    private imageInput:JQuery;
    private inputName:JQuery;
    private inputError:JQuery;
    private photoError:JQuery;
    private termsBox:JQuery;
    private btnContainer:JQuery;
    private inputWrap:JQuery;
    private mobileheader:JQuery;
    private desktopheader:JQuery;
    public imageFile:FormData;

    private editor:Editor;
    private record:Record;
    private uploadData:any;

    private hasBadWords:boolean = false;
    private isShowing:boolean;

    public static PHOTO_ERROR:string = "photoerror";
    public static PHOTO_PASSED:string = "photopassed";

    private fpo:boolean = false;
    private fpoImage:string = "https://s3.amazonaws.com/imposium-dev-barkleys/tracy.jpg";
    private containerHeight:number;


    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.photo').clone());
       
        this.inputName = this.content.find('.name');
        this.inputWrap = this.content.find('.inputWrap');
        this.inputName.on('focus', (e)=>this.removeError(e));
        this.inputName.on('input paste', (e) => this.checkInput(e, '^[a-zA-Z \s$-]+$', 'nameString'));
        this.uploadBtn = this.content.find('.btnUpload').on('click', ()=>this.pickImage());
        this.recordBtn = this.content.find('.btnPhoto').on('click', ()=>this.recordImage());
        this.btnContainer = this.content.find('.btns');
        this.imageInput = this.content.find('.imageInput').on('change', ()=>this.photoSelected());
        this.inputError = this.content.find('.inputError');
        this.photoError = this.content.find('.photoError');

        this.mobileheader =  this.content.find('.mobileHeader')
        this.desktopheader =  this.content.find('.deskHeader')

        this.content.on('scroll', ()=>this.blurInput());

        EventBus.addEventListener(Photo.PHOTO_ERROR, ()=>this.showPhotoError(), this)
        EventBus.addEventListener(Photo.PHOTO_PASSED, ()=>this.photoPassed(), this)
        this.termsBox = this.content.find('.terms').on('click', ()=>this.termsToggle());
         

        this.content.find('.noCam').hide();

    }

    public showMe(){
        super.showMe()
        let hasFlash = false;
        let isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > 0;
        let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isEdge = !isIE && !!window.StyleMedia;
        let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > 0;

        navigator.getUserMedia = ( navigator.getUserMedia ||
                               navigator.webkitGetUserMedia ||
                               navigator.mozGetUserMedia ||
                               navigator.msGetUserMedia);

        this.mobileheader.hide();
        if(Main.mobile){
            this.content.on("touchmove", ()=>this.blurInput());
            this.recordBtn.hide();
            this.content.find('.cam').hide();
            this.uploadBtn.html('Continue');
            this.mobileheader.show();
            this.desktopheader.hide();

        } else {
            if(isSafari && !isChrome || isIE || isEdge){
               hasFlash = ((typeof navigator.plugins != "undefined" && typeof navigator.plugins["Shockwave Flash"] == "object") || (window.ActiveXObject && (new ActiveXObject("ShockwaveFlash.ShockwaveFlash")) != false));  
                  if(hasFlash){
                      this.recordBtn.show();
                  } else {
                      this.hideTakePhotoBtn()
                  }
            } else {
                navigator.getUserMedia({ audio: false, video: true }, (e)=>this.mediaSuccess(e), (e)=>this.mediaError(e));
            }
            $(window).bind('resize orientationchange', ()=>this.resize());
        }


        if(VideoView.VIN){
            this.inputWrap.hide();
            this.inputError.hide();
            this.photoError.hide();
        } else {
            this.inputWrap.show();
        }
        this.isShowing = true;

        if(!Main.mobile){
            this.resize();
        }
    }


    private mediaError(e){
        this.hideTakePhotoBtn();
    }


    public hideTakePhotoBtn(){
        this.recordBtn.hide();
        this.content.find('.cam').hide();
        this.content.find('.noCam').show();
    }

    private mediaSuccess(e){
        this.recordBtn.show();
        var track = e.getTracks()[0];  // if only one media track
        track.stop();
        this.resize();
    }

    private blurInput(){
        this.inputName.blur();
    }

    public termsToggle(){
        if(this.termsBox.hasClass('checked')){
            this.termsBox.removeClass('checked');
            this.btnContainer.addClass('disabled');
        } else {
            this.termsBox.addClass('checked'); 
            this.btnContainer.removeClass('disabled');
        }
    }

    private recordImage(){
        ga('send', 'event', 'phase_two_input', 'click', 'take_photo');
        if(!VideoView.VIN && this.inputName.val().replace(/\s/g, '').length != 0){
            this.moderateText("record");
            this.delegate.firstName = this.inputName.val();
        } else if(VideoView.VIN){
            EventBus.dispatch(PhotoView.SHOW_RECORD);
        } else {
            this.showError();
            this.inputName.addClass('error');
        }
    }

    private pickImage(){
        if(!this.fpo && !VideoView.VIN && this.inputName.val().replace(/\s/g, '').length != 0){
            this.imageInput.click();
        }else if(this.fpo){
            let data = {
                'type':'url',
                'content':this.fpoImage
            } 
        } else if (VideoView.VIN){
            this.imageInput.click();
        } else {
            this.showError();
            this.inputName.addClass('error'); 
        }
    }

    private moderateText(type){
        let name = this.inputName.val().toLowerCase();
        this.hasBadWords = false;
        let url = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text='+name
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'jsonp',
            success: (data)=>{
                let badwords = data.rsp.found;
                if(badwords == 1){
                    this.hasBadWords = true;
                }
                if(type != "record"){
                    this.moderateImage();
                } else {
                    if(!this.hasBadWords){
                        this.delegate.firstName = this.inputName.val();
                        EventBus.dispatch(PhotoView.SHOW_RECORD);
                    } else {
                        this.showError();
                    }
                }

            },
            error: function(e, s, m){}
        })
    }


    private moderateImage(){
        EventBus.dispatch(PhotoView.CHECK_DATA, this.uploadData);
    }

    private checkInput(evt, r, field){

        let regex = new RegExp(r);
        let input = $(evt.target);

        if(evt.type == "input"){

            let val = input.val();

            if (regex.test(val) || val === ""){
                this.inputError.css('display', 'none');
                this[field] = val;
            }else{
                input.val(this[field]);
            }

        }else if(evt.type == "paste"){

            let paste = evt.originalEvent.clipboardData.getData('Text');
            if (!regex.test(paste)) {
                return false;
            }else{
                this.inputError.css('display', 'none');                
                return true;
            }
        }
    }

    public photoSelected(){
        let convertInput:any =  this.imageInput;
        if(this.imageInput.val().length > 1){
            if(!VideoView.VIN){
                this.delegate.firstName = this.inputName.val()
            }
            let data = {
                'type':'upload',
                'content':convertInput[0].files[0]
            }
            this.uploadData = data;
            ga('send', 'event', 'phase_two_input', 'click', 'upload_photo');
            if(!VideoView.VIN){
                this.moderateText("editor")
            } else {
                this.moderateImage()
            }
        }

        this.imageInput.val("");
    }
    private showError(){
        this.inputError.css('display', 'block');
    }

    private showPhotoError(){
        this.delegate.hideOverlay();
        if(this.hasBadWords){
            this.showError()
        }
        this.photoError.css('display', 'block');
    }

    private photoPassed(){
        if(!this.hasBadWords){
            EventBus.dispatch(PhotoView.SHOW_EDITOR, this.uploadData);
        } else {
            this.delegate.hideOverlay();
            this.showError();
        }
    }

    private removeError(e){
        this.inputName.removeClass('error'); 
        this.inputError.css('display', 'none');     
    }

    public resize(){
        if(this.isShowing){
            let h =  window.innerHeight;
            if(h > 768){
                this.container.scrollTop(0);
            }   
        }
    }

    public hideMe(){
        super.hideMe();
        if(Main.mobile){
            this.content.off("touchmove", ()=>this.blurInput());
        }
        this.isShowing = false;
        this.inputError.css('display', 'none');
        this.photoError.css('display', 'none');   
        this.termsBox.removeClass('checked');
        this.btnContainer.addClass('disabled')
        this.inputName.removeClass('error');
    }
}
