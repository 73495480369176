
/// <reference path='./Component.ts'/>
/// <reference path='./EventBus.ts'/>

class View extends Component{
    
    public static HIDE_ACTIVE:string = "hideactiveview";

    constructor(container, data, del){

        super(container, data ,del);

        EventBus.addEventListener(View.HIDE_ACTIVE, ()=>this.checkHide(), this);
        this.setRoutes();
    }

    public checkHide(){

        if(this.onStage){
            this.hideMe();
        }
    }

    public setRoutes(){

        page(this.data.route, req => {
            EventBus.dispatch(View.HIDE_ACTIVE);
            this.showMe();
        });
    }
}