var __extends = (this && this.__extends) || function (d, b) {
    for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    function __() { this.constructor = d; }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
};
/// <reference path='Main.ts'/>
/**
 * Helper functions available to all other Classes.
 */
var Utils = (function () {
    function Utils() {
    }
    Utils.getPercentage = function (message) {
        var messages = [
            'Adding job to queue...',
            'Added job to queue...',
            'Preparing dynamic content...',
            'Rendering dynamic video pieces...',
            'Uploading compiled video to S3...',
            'Video ready for viewing.'
        ];
        if (message == "Found existing content.") {
            return 1;
        }
        var index = messages.indexOf(message) + 1;
        var total = messages.length;
        var perc = index / total;
        return perc;
    };
    Utils.easeQuadOut = function (e0, e1, t) {
        return e0 + (e1 - e0) * (t * (2 - t));
    };
    Utils.padLeft = function (num) {
        if (num < 10) {
            num = '0' + num;
        }
        return num;
    };
    Utils.wrapText = function (context, text, x, y, maxWidth, lineHeight) {
        y = lineHeight;
        var words = text.split(' ');
        var line = '';
        for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
    };
    Utils.pow2floor = function (v) {
        v++;
        var p = 1;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    Utils.pow2ceil = function (v) {
        v--;
        var p = 2;
        while (v >>= 1) {
            p <<= 1;
        }
        return p;
    };
    /* --------------------------------------------------------*/
    /* --------------------- MISC HELPERS ---------------------*/
    /* --------------------------------------------------------*/
    /**
     * Resizes a rectangle to fit into another rectangle using different positioning and scale properties to crop / position.
     * @data    The data object containig all of the paraeters for the calculation.
     */
    Utils.fitToContainer = function (data) {
        var newH, newW, top, left;
        var aspectRatio = data.contentWidth / data.contentHeight;
        //scale
        if (data.scaleMode == "proportionalInside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH > data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "proportionalOutside") {
            newW = data.containerWidth;
            newH = newW / aspectRatio;
            if (newH < data.containerHeight) {
                newH = data.containerHeight;
                newW = newH * aspectRatio;
            }
        }
        else if (data.scaleMode == "none" || !data.scaleMode) {
            newW = data.contentWidth;
            newH = data.contentHeight;
        }
        if (data.maxWidth) {
            if (newW > data.maxWidth) {
                newW = data.maxWidth;
                newH = newW / aspectRatio;
            }
        }
        if (data.maxHeight) {
            if (newH > data.maxHeight) {
                newH = data.maxHeight;
                newW = newH * aspectRatio;
            }
        }
        //fit
        left = (data.hAlign == "left") ? 0 : (data.hAlign == "right") ? (data.containerWidth - newW) : (data.containerWidth - newW) / 2;
        top = (data.vAlign == "top") ? 0 : (data.vAlign == "bottom") ? (data.containerHeight - newH) : (data.containerHeight - newH) / 2;
        return {
            'width': newW,
            'height': newH,
            'top': top,
            'left': left
        };
    };
    /**
     * Generates a UUID and returns it.
     */
    Utils.generateUUID = function () {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
        return uuid;
    };
    /**
     * Generates a unique id, based off of the langth passed in.
     * @numChars    How may characters we want the (psuedo) unique ID to contain.
     */
    Utils.generateID = function (numChars) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < numChars; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        return text;
    };
    /**
     * Wrapper function to make an ajax request.
     * @data                The JSON data we want to pas in.
     * @successCallback     Callback function for on success.
     * @errorCallback       Callback function for error.
     */
    Utils.ajax = function (data, successCallback, errorCallback, ajaxUrl) {
        if (successCallback === void 0) { successCallback = null; }
        if (errorCallback === void 0) { errorCallback = null; }
        if (ajaxUrl === void 0) { ajaxUrl = null; }
        $.ajax({
            type: 'POST',
            url: 'ajax.cms.php',
            data: data,
            dataType: 'json',
            success: function (response) {
                if (successCallback) {
                    successCallback(response);
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                if (errorCallback) {
                    errorCallback(jqXHR.responseText);
                }
            }
        });
    };
    /**
     *Opens a popup window centered in the screen
     */
    Utils.openWindow = function (url, width, height) {
        var windowSize = {
            'width': width,
            'height': height,
            'left': (screen.width / 2) - (width / 2),
            'top': (screen.height / 2) - (height / 2 + 100)
        };
        var windowFeatures = "width=" + windowSize.width + ",height=" + windowSize.height + ",status,resizable,scrollbars,modal,alwaysRaised";
        windowFeatures += ",left=" + windowSize.left + ",top=" + windowSize.top + "screenX=" + windowSize.left + ",screenY=" + windowSize.top;
        window.open(url, '' + new Date().getTime() + '', windowFeatures);
    };
    /* --------------------------------------------------------*/
    /* ------------------------ STRING ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Capitalises the first letter of a string.
     * @str     The string you want to capitalise.
     */
    Utils.capitalize = function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    /**
     * Returns a formatted string for displaying the file size, from bytes.
     * @bytes       The filesize, in bytes.
     */
    Utils.formatBytes = function (bytes) {
        if (bytes >= 1000000000) {
            bytes = (bytes / 1000000000).toFixed(2) + ' GB';
        }
        else if (bytes >= 1000000) {
            bytes = (bytes / 1000000).toFixed(2) + ' MB';
        }
        else if (bytes >= 1000) {
            bytes = (bytes / 1000).toFixed(2) + ' KB';
        }
        else if (bytes > 1) {
            bytes = bytes + ' bytes';
        }
        else if (bytes == 1) {
            bytes = bytes + ' byte';
        }
        else {
            bytes = '0 byte';
        }
        return bytes;
    };
    /* --------------------------------------------------------*/
    /* ------------------------ OBJECT ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Counts the key/value pairs in an object.
     * @obj     The object you want to get a "length" from.
     */
    Utils.objSize = function (obj) {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key))
                size++;
        }
        return size;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- NUMBERS ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if a value is numberic or not.
     * @n   The value we want to check.
     */
    Utils.isNumeric = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    };
    /**
     * Detects if a number is odd or event
     * @num     The value we want to check.
     */
    Utils.isOdd = function (num) {
        return (num % 2) == 16;
    };
    /**
     * Contain a number to a min and max.
     */
    Utils.clamp = function (min, max, val) {
        if (val < min)
            return min;
        if (val > max)
            return max;
        return val;
    };
    /* --------------------------------------------------------*/
    /* ------------------------- MATH -------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Convert degrees to radians.
     */
    Utils.degreesToRadians = function (degrees) {
        return degrees * Math.PI / 180;
    };
    /**
     * Convert radians to degrees
     */
    Utils.radiansToDegrees = function (radians) {
        return radians * 180 / Math.PI;
    };
    /**
     * Calculates the distance between two points in 2D space.
     */
    Utils.lineDistance = function (point1, point2) {
        var xs = 0;
        var ys = 0;
        xs = point2.x - point1.x;
        xs = xs * xs;
        ys = point2.y - point1.y;
        ys = ys * ys;
        return Math.sqrt(xs + ys);
    };
    /**
     * Calculates the angle in degrees between two points
     */
    Utils.calcAngle = function (p1, p2) {
        var calcAngle = Math.atan2(p1.x - p2.x, p1.y - p2.y) * (180 / Math.PI);
        if (calcAngle < 0)
            calcAngle = Math.abs(calcAngle);
        else
            calcAngle = 360 - calcAngle;
        return calcAngle;
    };
    /**
     * Returns a random number between 2 numbers
     */
    Utils.randomFromInterval = function (from, to) {
        return Math.floor(Math.random() * (to - from + 1) + from);
    };
    Utils.dataURItoBlob = function (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = decodeURIComponent(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    };
    Utils.getMidPoint = function (p1, p2) {
        var x = (p1.x + p2.x) / 2;
        var y = (p1.y + p2.y) / 2;
        return { "x": x, "y": y };
    };
    /* --------------------------------------------------------*/
    /* ------------------------- ARRAY ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Switches the position of two array elements.
     * @array       The array containing both elements.
     * @a           The index of the first element.
     * @b           The index of the second element.
     * @return      The array with the elements switched.
     */
    Utils.swapArrayElements = function (array, a, b) {
        var temp = array[a];
        array[a] = array[b];
        array[b] = temp;
        return array;
    };
    /**
     * Removes one of more elements from an array.
     * @array       The array containing all of the elements.
     * @from        The first element we want to remove (and the last, if @to isn't set).
     * @to          The index of the last element we want to remove.
     * @return      The original array with the elements removed.
     */
    Utils.removeFromArray = function (array, from, to) {
        var rest = array.slice((to || from) + 1 || array.length);
        array.length = from < 0 ? array.length + from : from;
        return array.push.apply(array, rest);
    };
    /**
     * Randomly shuffles the contents of an array.
     * @array       The array containing all of the elements.
     */
    Utils.shuffleArray = function (array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
    /* --------------------------------------------------------*/
    /* -------------------------- JSON ------------------------*/
    /* --------------------------------------------------------*/
    /**
     * Checks if a string is valid json.
     * @str     The string you want to check.
     */
    Utils.isValidJSON = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    /**
     * Formats a JSON sting with line breaks for displaying pretty JSON.
     * @str     The string you want to format.
     */
    Utils.formatJSONString = function (str) {
        var jsonObj = JSON.parse(str);
        var jsonPretty = JSON.stringify(jsonObj, null, '\t');
        return jsonPretty;
    };
    /* --------------------------------------------------------*/
    /* ----------------- BROWSER / OS DETECTION ---------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects the operating system on a mobile device, returns and os and the version.
     */
    Utils.detectMobileOS = function () {
        var mobileOS;
        var mobileOSver;
        var ua = navigator.userAgent;
        var uaindex;
        // determine OS
        if (ua.match(/iPad/i) || ua.match(/iPhone/i)) {
            mobileOS = 'iOS';
            uaindex = ua.indexOf('OS ');
        }
        else if (ua.match(/Android/i)) {
            mobileOS = 'Android';
            uaindex = ua.indexOf('Android ');
        }
        else {
            mobileOS = 'unknown';
        }
        // determine version
        if (mobileOS === 'iOS' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 3, 3).replace('_', '.');
        }
        else if (mobileOS === 'Android' && uaindex > -1) {
            mobileOSver = ua.substr(uaindex + 8, 3);
        }
        else {
            mobileOSver = 'unknown';
        }
        var num = Number(mobileOSver);
        return { "os": mobileOS, "ver": num };
    };
    /* --------------------------------------------------------*/
    /* -------------------- FEATURE DETECTION -----------------*/
    /* --------------------------------------------------------*/
    /**
     * Detects if the current browser can play the mp4 video format or now.
     */
    Utils.canPlayMP4 = function () {
        var canPlay = false;
        var v = document.createElement('video');
        if (v.canPlayType && v.canPlayType('video/mp4').replace(/no/, '')) {
            canPlay = true;
        }
        return canPlay;
    };
    /* --------------------------------------------------------*/
    /* ----------------------- DATE / TIME --------------------*/
    /* --------------------------------------------------------*/
    Utils.clientTimezone = function (date) {
        return date.addHours(-(new Date().getTimezoneOffset() / 60));
    };
    Utils.formatDate = function (date, format) {
        var d = Utils.clientTimezone(date);
        return date.toString(format).replace(' 0:', ' 12:'); // Fix date.js bug to show 12:00am instead of 0:00am
    };
    /* --------------------------------------------------------*/
    /* ------------------------ COOKIES -----------------------*/
    /* --------------------------------------------------------*/
    /**
     *Sets a cookie to the document object
     * @c_name      The name for the new cookie.
     * @value       The data to set on this cookie.
     * @exdays      How many days befor this cookie expires.
     */
    Utils.setCookie = function (c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = value + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString()) + ";path=/";
        document.cookie = c_name + "=" + c_value;
    };
    /**
     * Retrives a cookie from the document object using the cookies name
     * @c_name      Label of the cookie you want to retrieve.
     */
    Utils.getCookie = function (c_name) {
        var c_value = document.cookie;
        var c_start = c_value.indexOf(" " + c_name + "=");
        if (c_start == -1) {
            c_start = c_value.indexOf(c_name + "=");
        }
        if (c_start == -1) {
            c_value = null;
        }
        else {
            c_start = c_value.indexOf("=", c_start) + 1;
            var c_end = c_value.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = c_value.length;
            }
            c_value = c_value.substring(c_start, c_end);
        }
        return c_value;
    };
    /* --------------------------------------------------------*/
    /* ---------------------- VALIDATION ----------------------*/
    /* --------------------------------------------------------*/
    /**
     * Validates an email address.
     */
    Utils.realEmail = function (addressToTest) {
        var regPattern = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regPattern.test(addressToTest);
    };
    return Utils;
}());
/// <reference path='./Utils.ts'/>
/**
 * Main static class, holds all global config variables and functions for all views and components.
 */
var Main = (function () {
    /**
     * Creates a new Main instance, sets the templates for all views and components to use, creates a global socket connection, and gets the saved state from a cookie.
     *
     * @param config    Configuration variables passed in from config.php.
     */
    function Main() {
        var _this = this;
        //set the php config variables
        this.setPrefixes();
        Main.templates = $("#templates");
        var isMobile = Utils.detectMobileOS();
        Main.mobile = (isMobile.os !== "unknown");
        if (isMobile.os == "iOS") {
            $('#container').addClass('iphone');
        }
        Main.isIE = false || !!document.documentMode;
        var isEdge = !Main.isIE && !!window.StyleMedia;
        if (isEdge) {
            $('#container').addClass('edge');
        }
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
        var onEvt = (Main.mobile) ? 'touchstart' : 'mouseenter';
        var offEvt = (Main.mobile) ? 'touchend touchleave touchcancel' : 'mouseleave mouseout';
        $(document).on(onEvt, '.interactive', function (e) {
            $(this).addClass('hover');
        });
        $(document).on(offEvt, '.interactive', function (e) {
            $(this).removeClass('hover');
        });
        rivets.formatters["dateTime"] = function (val) {
            var d = new Date(val * 1000);
            var hours = d.getHours();
            var ampm = 'AM';
            if (hours >= 12) {
                ampm = 'PM';
            }
            if (hours > 12) {
                hours -= 12;
            }
            var dformat = [Utils.padLeft((d.getMonth() + 1)),
                Utils.padLeft(d.getDate()),
                d.getFullYear()].join('/') + ' ' +
                [Utils.padLeft(hours),
                    Utils.padLeft(d.getMinutes())].join(':');
            return dformat + ' ' + ampm;
        };
        rivets.formatters["prettyTime"] = function (val) {
            var min = Math.floor(val / 60);
            var sec = Math.floor(val % 60);
            var minStr = (min) ? min + ':' : '0:';
            var secStr = (sec > 9) ? sec : '0' + sec;
            return minStr + secStr;
        };
    }
    Main.prototype.resize = function () {
        Main.sWidth = window.innerWidth;
        Main.sHeight = window.innerHeight;
    };
    /**
     * Sets Main.xform and Main.transition by detecting which prefix exists on the current browser.
     */
    Main.prototype.setPrefixes = function () {
        var view = document.createElement('div');
        var prefixes = ['webkit', 'Moz', 'O', 'ms'];
        for (var i = 0; i < prefixes.length; i++) {
            var prefix = prefixes[i];
            var e = prefix + 'Transform';
            var t = prefix + 'Transition';
            if (typeof view.style[e] !== 'undefined') {
                Main.xform = e;
                Main.transition = t;
            }
        }
        view = null;
    };
    Main.mobile = false;
    Main.phaseTwoLive = true;
    Main.isOwner = false;
    Main.isIE = false;
    return Main;
}());
/// <reference path='./Utils.ts'/>
var Component = (function () {
    /**
     * Stores the global vars
     *
     * @param container A jQuery object containing the parent div for this view.
     * @param data      The config JSON data associated with this component.
     * @param delegate  The Class that instantiated this view.
     */
    function Component(container, data, delegate) {
        /**
         * Whether of not this instance is visible or not.
         */
        this.onStage = false;
        /**
         * The delay for setting display:none on this view after the showContent class is removed.
         */
        this.hideDelay = 0;
        /**
         * The delay for adding the showContent class to this view once display:block has been set.
         */
        this.showDelay = 25;
        /**
         * The object containing event listeners added to this specific component
         */
        this.listeners = {};
        /**
         * The object containing event listeners to be fired once added to this component
         */
        this.onceListeners = {};
        /**
         * The class to use for showing this component
         */
        this.displayClass = "block";
        this.uuid = Utils.generateUUID();
        this.container = container;
        this.delegate = delegate;
        this.data = data;
    }
    /**
     * Sets this.content with a JQuery object passed in (usually cloned from Main.templates)
     *
     * @param v JQuery wrapped DOM element
     */
    Component.prototype.setContent = function (v) {
        this.content = v;
        this.rivets = rivets.bind(this.content, {
            data: this.data,
            controller: this
        });
        this.container.append(this.content);
    };
    /**
     * Adds an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to add an event listener for.
     * @param callback The function we want to call if that event is dispatched.
     * @param caller   Who called this
     */
    Component.prototype.on = function (evt, callback, caller) {
        if (!this.listeners[evt]) {
            this.listeners[evt] = {};
        }
        this.listeners[evt][caller.guid] = callback;
    };
    /**
     * Removes an event listener for an event dispatched from this specific instance.
     *
     * @param evt      Event string we want to remove an event listener for.
     * @param callback The function we want to remove from the listeners array for that event.
     * @param caller   Who called this
     */
    Component.prototype.off = function (evt, callback, caller) {
        var listeners = this.listeners[evt];
        delete listeners[caller.guid];
    };
    /**
     * Dispatches an event to any Classes listening for it from this instance.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass back to the event listener function.
     */
    Component.prototype.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = this.listeners[evt];
        var onceListeners = this.onceListeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
        for (var key in onceListeners) {
            if (onceListeners.hasOwnProperty(key)) {
                onceListeners[key](data);
                delete onceListeners[key];
            }
        }
    };
    /**
     * Adds display block to this.content, and calls showContent
     */
    Component.prototype.showMe = function () {
        var _this = this;
        var style = (this.displayClass == "visible") ? "visibility" : "display";
        this.content.css(style, this.displayClass);
        this.onStage = true;
        clearTimeout(this.displayTimeout);
        this.displayTimeout = setTimeout(function () {
            _this.showContent();
        }, this.showDelay);
    };
    /**
     * Adds display none to this.content, calls hideContent
     */
    Component.prototype.hideMe = function () {
        var _this = this;
        if (this.onStage) {
            this.onStage = false;
            var style = (this.displayClass == "visible") ? "visibility" : "display";
            var hide = (style == "display") ? "none" : "hidden";
            this.hideContent();
            clearTimeout(this.displayTimeout);
            this.displayTimeout = setTimeout(function () {
                _this.content.css(style, hide);
            }, this.hideDelay);
        }
    };
    /**
     * Adds the showContent class and removes the hideContent class from this component.
     */
    Component.prototype.showContent = function () {
        this.content.removeClass('hideContent');
        this.content.addClass('showContent');
    };
    /**
     * Adds the hideContent class and removes the showContent class from this component.
     */
    Component.prototype.hideContent = function () {
        this.content.removeClass('showContent');
        this.content.addClass('hideContent');
    };
    /**
     * Kills this component, removes the event listeners, removes this.content, and set's itself to null;
     */
    Component.prototype.killMe = function () {
        var _this = this;
        clearTimeout(this.killTimeout);
        this.killTimeout = setTimeout(function () {
            if (_this.content) {
                _this.content.remove();
            }
            _this.content = null;
        }, this.hideDelay);
    };
    return Component;
}());
/**
 * Global event listener class. Used for passing global between views and components.
 */
var EventBus = (function () {
    function EventBus() {
    }
    /**
     * Adds an event listener for a global event.
     *
     * @param evt      Event string we're listening for
     * @param callback The callback function to call if the event is dispatched.
     * @param caller   Who called this
     */
    EventBus.addEventListener = function (evt, callback, caller) {
        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = {};
        }
        EventBus.listeners[evt][caller.uuid] = callback;
    };
    /**
     * Removes a specific event listener for a global event.
     *
     * @param evt      Event string we're removing
     * @param callback The callback function we want to remove.
     * @param caller   Who called this
     */
    EventBus.removeEventListener = function (evt, callback, caller) {
        var listeners = EventBus.listeners[evt];
        delete listeners[caller.uuid];
    };
    /**
     * Adds an event listener for a global event.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass into the callback function for that event.
     */
    EventBus.dispatch = function (evt, data) {
        if (data === void 0) { data = null; }
        var listeners = EventBus.listeners[evt];
        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    };
    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */
    EventBus.listeners = {};
    return EventBus;
}());
/// <reference path='./Component.ts'/>
/// <reference path='./EventBus.ts'/>
var View = (function (_super) {
    __extends(View, _super);
    function View(container, data, del) {
        var _this = this;
        _super.call(this, container, data, del);
        EventBus.addEventListener(View.HIDE_ACTIVE, function () { return _this.checkHide(); }, this);
        this.setRoutes();
    }
    View.prototype.checkHide = function () {
        if (this.onStage) {
            this.hideMe();
        }
    };
    View.prototype.setRoutes = function () {
        var _this = this;
        page(this.data.route, function (req) {
            EventBus.dispatch(View.HIDE_ACTIVE);
            _this.showMe();
        });
    };
    View.HIDE_ACTIVE = "hideactiveview";
    return View;
}(Component));
/// <reference path='../scaffolding/View.ts'/>
var IntroView = (function (_super) {
    __extends(IntroView, _super);
    function IntroView(container, data, delegate) {
        _super.call(this, container, data, delegate);
        this.setContent($("#container").find('.introView'));
        this.content.find('.btnGetStarted').on('click', function () {
            ga('send', 'event', 'phase_one_landing', 'click', 'create_video');
            page('/info');
        });
        this.hideDelay = 500;
    }
    IntroView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Intro1);
    };
    return IntroView;
}(View));
/// <reference path='../scaffolding/View.ts'/>
var InfoView = (function (_super) {
    __extends(InfoView, _super);
    function InfoView(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.nameString = "";
        this.occasionString = "";
        this.setContent(Main.templates.find('.infoView'));
        this.nextBtn = this.content.find('.btnGenerate');
        this.inputName = this.content.find('.name');
        this.inputOccasion = this.content.find('.occasion');
        this.termsBox = this.content.find('.terms').on('click', function () { return _this.termsToggle(); });
        this.nextBtn.on('click', function () { return _this.moderateText(); });
        this.inputName.on('focus', function (e) { return _this.removeError(e); });
        this.inputOccasion.on('focus', function (e) { return _this.removeError(e); });
        this.inputOccasion.on('input paste', function (e) { return _this.checkInput(e, '^[a-zA-Z0-9 \s$-]+$', 'occasionString'); });
        this.inputName.on('input paste', function (e) { return _this.checkInput(e, '^[a-zA-Z \s$-]+$', 'nameString'); });
        this.inputError = this.content.find('.inputError');
        this.hideDelay = 500;
    }
    InfoView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Info);
    };
    InfoView.prototype.termsToggle = function () {
        if (this.termsBox.hasClass('checked')) {
            this.termsBox.removeClass('checked');
            this.nextBtn.attr("disabled", "disabled").addClass('disabled');
        }
        else {
            this.termsBox.addClass('checked');
            this.nextBtn.removeAttr("disabled").removeClass('disabled');
        }
    };
    InfoView.prototype.checkInventory = function () {
        if (this.checkValid()) {
            var data = {
                inventory: {
                    'first_name': this.inputName.val(),
                    'action': this.inputOccasion.val()
                },
                phase: VideoView.PHASE_ONE,
            };
            ga('send', 'event', 'phase_one_input', 'click', 'continue');
            EventBus.dispatch(VideoView.GENERATE_VIDEO, data);
            this.inputError.css('visibility', 'hidden');
            this.hideMe();
        }
        else {
            // this.clearInputs();
            this.showError();
        }
    };
    InfoView.prototype.checkValid = function () {
        var isValid = true;
        this.removeError(null);
        if (this.inputName.val().replace(/\s/g, '').length == 0) {
            this.inputName.addClass('error');
            isValid = false;
        }
        if (this.inputOccasion.val().replace(/\s/g, '').length == 0) {
            this.inputOccasion.addClass('error');
            isValid = false;
        }
        if (!this.termsBox.hasClass('checked')) {
            isValid = false;
        }
        return isValid;
    };
    InfoView.prototype.moderateText = function () {
        var _this = this;
        var name = this.inputName.val().toLowerCase();
        var occasion = this.inputOccasion.val().toLowerCase();
        var url = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text=' + name;
        var urlTwo = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text=' + occasion;
        var hasBadWords;
        $.when($.ajax({
            type: 'GET',
            url: url,
            dataType: 'jsonp',
            success: function (data) {
                var nameResponse = data.rsp.found;
                if (nameResponse == 1) {
                    hasBadWords = true;
                    _this.inputName.addClass('error');
                }
            },
            error: function (e, s, m) { }
        }), $.ajax({
            type: 'GET',
            url: urlTwo,
            dataType: 'jsonp',
            success: function (data) {
                var occasionResponse = data.rsp.found;
                if (occasionResponse == 1) {
                    hasBadWords = true;
                    _this.inputOccasion.addClass('error');
                }
            },
        })).then(function () {
            if (!hasBadWords) {
                _this.checkInventory();
            }
            else {
                _this.showError();
            }
        });
    };
    InfoView.prototype.showError = function () {
        this.inputError.css('visibility', 'visible');
    };
    InfoView.prototype.removeError = function (e) {
        if (e) {
            $(e.target).removeClass('error');
            this.inputError.css('visibility', 'hidden');
        }
        else {
            this.inputName.removeClass('error');
            this.inputOccasion.removeClass('error');
            this.inputError.css('visibility', 'hidden');
        }
        this.inputError.css('visibility', 'hidden');
    };
    InfoView.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        this.inputError.css('visibility', 'hidden');
        this.termsBox.removeClass('checked');
        this.nextBtn.attr("disabled", "disabled").addClass('disabled');
        this.inputName.removeClass('error');
        this.inputOccasion.removeClass('error');
        this.nextBtn.removeClass('hover');
    };
    InfoView.prototype.checkInput = function (evt, r, field) {
        var regex = new RegExp(r);
        var input = $(evt.target);
        if (evt.type == "input") {
            var val = input.val();
            if (regex.test(val) || val === "") {
                this.inputError.css('visibility', 'hidden');
                this[field] = val;
            }
            else {
                input.val(this[field]);
            }
        }
        else if (evt.type == "paste") {
            var paste = evt.originalEvent.clipboardData.getData('Text');
            if (!regex.test(paste)) {
                return false;
            }
            else {
                this.inputError.css('visibility', 'hidden');
                return true;
            }
        }
    };
    InfoView.prototype.clearInputs = function () {
        this.inputName.val("");
        this.inputOccasion.val("");
    };
    return InfoView;
}(View));
var CanvasHandler = (function (_super) {
    __extends(CanvasHandler, _super);
    function CanvasHandler(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        //config
        this.eyeY = 140;
        this.eyeWidth = 120;
        this.width = 290;
        this.height = 352;
        this.bigWidth = 870;
        this.bigHeight = 1056;
        this.mobileWidth = 174;
        this.mobileHeight = 211;
        this.debug = false;
        this.posScale = 1;
        //dragging
        this.dragging = false;
        //scaling
        this.pinching = false;
        this.rotating = false;
        this.initRotate = 0;
        this.maxRotation = 360;
        this.minRotation = 0;
        this.minScale = 0.1;
        this.maxScale = 5;
        //change scale for mobile and add touch event listeners
        if (Main.mobile) {
            this.posScale = this.mobileWidth / this.width;
            this.width = this.mobileWidth;
            this.height = this.mobileHeight;
        }
        this.canvasScale = this.bigWidth / this.width;
        //create the pixi stage for manipulating the image
        this.mainStage = new PIXI.Container();
        this.mainStage.interactive = true;
        this.mainRenderer = new PIXI.CanvasRenderer(this.width, this.height, { 'transparent': true });
        this.container.find('.face').prepend(this.mainRenderer.view);
        //create the pixi stage for creating the large image
        this.bigStage = new PIXI.Container();
        this.bigStage.interactive = true;
        this.bigRenderer = new PIXI.CanvasRenderer(870, 1056, { 'transparent': true });
        //create the fileReader for getting the dataURL from the uploaded image
        this.fileReader = new FileReader();
        this.fileReader.onload = function (e) { return _this.processImage(e); };
        if (Main.mobile) {
            this.handleTouch();
        }
        else {
            this.handleDesktop();
        }
    }
    CanvasHandler.prototype.handleRecord = function (data) {
        this.detectFaces = true;
        this.recordBoundingBox = data.box;
        var d = { 'target': { 'result': data.content } };
        this.processImage(d);
    };
    CanvasHandler.prototype.handleURL = function (img) {
        this.detectFaces = true;
        this.recordBoundingBox = null;
        var d = { 'target': { 'result': img } };
        this.processImage(d);
    };
    CanvasHandler.prototype.handleUpload = function (img) {
        var _this = this;
        this.detectFaces = true;
        this.recordBoundingBox = null;
        //first get the EXIF data and figure out if we have to rotate the image
        EXIF.getData(img, function () {
            _this.orientation = EXIF.getAllTags(img).Orientation;
            _this.fileReader.readAsDataURL(img);
        });
    };
    CanvasHandler.prototype.resizeSprite = function (scale) {
        this.sprite.scale.x = scale;
        this.sprite.scale.y = scale;
    };
    CanvasHandler.prototype.imageToUpload = function () {
        var texture = PIXI.Texture.fromImage(this.apiImg);
        var sprite = new PIXI.Sprite(texture);
        sprite.anchor.x = this.sprite.anchor.x;
        sprite.anchor.y = this.sprite.anchor.y;
        sprite.position.x = this.sprite.position.x * this.canvasScale;
        sprite.position.y = this.sprite.position.y * this.canvasScale;
        sprite.scale.x = this.sprite.scale.x * this.canvasScale;
        sprite.scale.y = this.sprite.scale.y * this.canvasScale;
        sprite.rotation = this.sprite.rotation;
        this.bigStage.addChild(sprite);
        this.bigRenderer.render(this.bigStage);
        var url = this.bigRenderer.view.toDataURL();
        this.bigStage.removeChild(sprite);
        if (this.debug) {
            this.bigRenderer.view.id = "bigRenderer";
            $("#container").append(this.bigRenderer.view);
        }
        return url;
    };
    CanvasHandler.prototype.clearCanvas = function () {
        for (var i = this.mainStage.children.length - 1; i >= 0; i--) {
            this.mainStage.removeChild(this.mainStage.children[i]);
        }
        ;
        for (var i = this.bigStage.children.length - 1; i >= 0; i--) {
            this.bigStage.removeChild(this.bigStage.children[i]);
        }
        ;
        this.mainRenderer.render(this.mainStage);
        cancelAnimationFrame(this.raf);
    };
    CanvasHandler.prototype.animate = function () {
        var _this = this;
        this.raf = requestAnimationFrame(function () { return _this.animate(); });
        this.mainRenderer.render(this.mainStage);
    };
    CanvasHandler.prototype.dragStart = function (e) {
        var _this = this;
        this.dragData = {
            'x': e.clientX,
            'y': e.clientY
        };
        this.dragging = true;
        $(this.mainRenderer.view).on('mousemove', function (e) { return _this.dragMove(e); });
        $(this.mainRenderer.view).on('mouseup mouseupoutside mouseleave', function (e) { return _this.dragEnd(e); });
    };
    CanvasHandler.prototype.dragMove = function (e) {
        if (!this.pinching) {
            if (this.dragging) {
                var offX = e.clientX - this.dragData.x;
                var offY = e.clientY - this.dragData.y;
                this.sprite.position.x += offX;
                this.sprite.position.y += offY;
                this.dragData = {
                    'x': e.clientX,
                    'y': e.clientY
                };
            }
        }
    };
    CanvasHandler.prototype.dragEnd = function (e) {
        this.dragging = false;
        this.dragData = null;
        $(this.mainRenderer.view).off('mousemove');
        $(this.mainRenderer.view).off('mouseup');
    };
    CanvasHandler.prototype.panStart = function (e) {
        this.dragData = {
            'x': e.center.x,
            'y': e.center.y
        };
        this.dragging = true;
    };
    CanvasHandler.prototype.panMove = function (e) {
        if (!this.pinching) {
            if (this.dragging) {
                var offX = e.center.x - this.dragData.x;
                var offY = e.center.y - this.dragData.y;
                this.sprite.position.x += offX;
                this.sprite.position.y += offY;
                this.dragData = {
                    'x': e.center.x,
                    'y': e.center.y
                };
            }
        }
    };
    CanvasHandler.prototype.panEnd = function (e) {
        this.dragging = false;
        this.dragData = null;
    };
    CanvasHandler.prototype.pinchStart = function (e) {
        e.preventDefault();
        this.scaleOffset = this.sprite.scale.x;
        this.pinching = true;
    };
    CanvasHandler.prototype.pinchEnd = function (e) {
        e.preventDefault();
        this.pinching = false;
    };
    CanvasHandler.prototype.pinchZoom = function (e) {
        e.preventDefault();
        var scale = this.scaleOffset * e.scale;
        if (scale < this.minScale)
            scale = this.minScale;
        if (scale > this.maxScale)
            scale = this.maxScale;
        this.sprite.scale.x = scale;
        this.sprite.scale.y = scale;
    };
    CanvasHandler.prototype.scrollZoom = function (e) {
        var zoomChange = -0.1;
        var delta = (e.originalEvent.wheelDelta) ? e.originalEvent.wheelDelta : e.originalEvent.deltaY;
        if (delta > 0) {
            zoomChange = 0.1;
        }
        var nScale = this.barScale + zoomChange;
        this.barScale = nScale;
        if (this.barScale < 0.005) {
            this.barScale = 0.005;
        }
        else if (this.barScale > 2.9) {
            this.barScale = 2.9;
        }
        this.sprite.scale.x = this.barScale;
        this.sprite.scale.y = this.barScale;
        this.delegate.repositionScrubber(this.barScale);
        e.preventDefault();
    };
    CanvasHandler.prototype.handleTouch = function () {
        var _this = this;
        var options = {
            preventDefault: true
        };
        var targ = this.mainRenderer.view;
        this.hammer = new Hammer.Manager(targ);
        var rotate = new Hammer.Rotate();
        var pinch = new Hammer.Pinch();
        pinch.recognizeWith(rotate);
        var pan = new Hammer.Pan();
        this.hammer.add([pinch, pan, rotate]);
        this.hammer.on("panstart", function (e) { return _this.panStart(e); });
        this.hammer.on("panmove", function (e) { return _this.panMove(e); });
        this.hammer.on("panend", function (e) { return _this.panEnd(e); });
        this.hammer.on('rotatestart', function (e) { return _this.rotateStart(e); });
        this.hammer.on('rotatemove', function (e) { return _this.rotateMove(e); });
        this.hammer.on('rotateend', function (e) { return _this.rotateStop(e); });
        this.hammer.get('pinch').set({ enable: true });
        this.hammer.on("pinch", function (e) { return _this.pinchZoom(e); });
        this.hammer.on("pinchstart", function (e) { return _this.pinchStart(e); });
        this.hammer.on("pinchend", function (e) { return _this.pinchEnd(e); });
    };
    CanvasHandler.prototype.handleDesktop = function () {
        var _this = this;
        $(this.mainRenderer.view).on('mousedown', function (e) { return _this.dragStart(e); });
        $(this.mainRenderer.view).on('wheel', function (e) { return _this.scrollZoom(e); });
    };
    CanvasHandler.prototype.processImage = function (e) {
        var _this = this;
        var img = new Image();
        // img.crossOrigin = 'Anonymous';
        img.onload = function () {
            //position vars
            var w = (_this.orientation <= 4 || !_this.orientation) ? img.naturalWidth : img.naturalHeight;
            var h = (_this.orientation <= 4 || !_this.orientation) ? img.naturalHeight : img.naturalWidth;
            var scaleX = 1;
            var scaleY = 1;
            var x = -w / 2;
            var y = -h / 2;
            var rotation = 0;
            //create the canvas for rotating the positining the large version of the image
            var canvas = document.createElement('canvas');
            canvas.id = "orientationCanvas";
            canvas.width = w;
            canvas.height = h;
            var context = canvas.getContext('2d');
            context.translate(w / 2, h / 2);
            //based off of the exif data, rotate, position and scale the image. 
            switch (_this.orientation) {
                case 2:
                    scaleX = -1;
                    scaleY - 1;
                    break;
                case 3:
                    rotation = 180;
                    break;
                case 4:
                    rotation = 180;
                    scaleX = -1;
                    scaleY - 1;
                    break;
                case 5:
                    rotation = 90;
                    x = -h / 2;
                    y = -w / 2;
                    scaleX = 1;
                    scaleY = -1;
                    break;
                case 6:
                    rotation = 90;
                    x = -h / 2;
                    y = -w / 2;
                    break;
                case 7:
                    rotation = -90;
                    x = -h / 2;
                    y = -w / 2;
                    scaleX = 1;
                    scaleY = -1;
                    break;
                case 8:
                    rotation = -90;
                    x = -h / 2;
                    y = -w / 2;
                    break;
            }
            //position the image
            context.rotate(Utils.degreesToRadians(rotation));
            context.scale(scaleX, scaleY);
            context.drawImage(img, x, y);
            //create a resized version of the image to send to the API
            _this.resizedWidth = w;
            _this.resizedHeight = h;
            var r = w / h;
            var cWidth = (w > h) ? 1920 : 1080;
            _this.apiImg = canvas.toDataURL();
            var resizeCanvas;
            //if the image is larger than the target "small" width, scale it
            if (w > cWidth) {
                var cHeight = cWidth / r;
                resizeCanvas = document.createElement('canvas');
                resizeCanvas.id = "resizeCanvas";
                var resizeContext = resizeCanvas.getContext('2d');
                resizeCanvas.width = cWidth;
                resizeCanvas.height = cHeight;
                resizeContext.drawImage(canvas, 0, 0, cWidth, cHeight);
                _this.resizedWidth = cWidth;
                _this.resizedHeight = cHeight;
                _this.apiImg = resizeCanvas.toDataURL();
            }
            //if in debug mode, put the canvases on the stage
            if (_this.debug) {
                $("#container").append(canvas).append(resizeCanvas);
            }
            _this.moderateImage(_this.apiImg);
        };
        //load the image from the data URL
        img.src = e.target.result;
    };
    CanvasHandler.prototype.moderateImage = function (fileData) {
        var _this = this;
        var blob = Utils.dataURItoBlob(fileData); //convert the data URI to a blob so it works with their API
        $.ajax({
            url: "https://westus.api.cognitive.microsoft.com/vision/v1.0/analyze/?visualFeatures=Adult",
            type: "POST",
            data: blob,
            processData: false,
            contentType: false,
            success: function (data) {
                _this.adult = data.adult.isAdultContent || data.adult.isRacyContent;
                if (!_this.adult) {
                    if (_this.detectFaces) {
                        _this.findFaces(_this.apiImg);
                    }
                    else {
                        _this.addFaceToCanvas(null, _this.apiImg);
                    }
                }
                else {
                    EventBus.dispatch(Photo.PHOTO_ERROR);
                }
            },
            error: function (e) {
                console.error(e);
                EventBus.dispatch(Photo.PHOTO_ERROR);
            },
            beforeSend: function (r) {
                r.setRequestHeader("Content-Type", "application/octet-stream");
                r.setRequestHeader("Ocp-Apim-Subscription-Key", "76ff50c2958e44ef9adacc2b9612c031");
            }
        });
    };
    //converts the data URI to a blob, and uploads it to the Face API for decection
    CanvasHandler.prototype.findFaces = function (img) {
        var _this = this;
        var blob = Utils.dataURItoBlob(img);
        $.ajax({
            url: "https://westus.api.cognitive.microsoft.com/face/v1.0/detect?returnFaceLandmarks=true",
            type: "POST",
            data: blob,
            processData: false,
            contentType: false,
            success: function (data) {
                _this.addFaceToCanvas(data[0], img);
            },
            error: function (e) {
                console.error(e);
                this.addFaceToCanvas(null, img);
            },
            beforeSend: function (r) {
                r.setRequestHeader("Content-Type", "application/octet-stream");
                r.setRequestHeader("Ocp-Apim-Subscription-Key", "1808ff2d41cc47c69b6f748ac8b58297");
            }
        });
    };
    //puts the sprite on the canvas for user interaction
    CanvasHandler.prototype.addFaceToCanvas = function (faceData, imgData) {
        EventBus.dispatch(Photo.PHOTO_PASSED);
        //create a new sprite
        var texture = PIXI.Texture.fromImage(imgData);
        this.sprite = new PIXI.Sprite(texture);
        //set the initial position of the sprite, this function will return defaults if there's no face data found on the api
        var initialPosition = this.getInitialPosition(faceData);
        this.sprite.anchor.x = initialPosition.anchor.x;
        this.sprite.anchor.y = initialPosition.anchor.y;
        this.sprite.position.x = initialPosition.position.x;
        this.sprite.position.y = initialPosition.position.y;
        if (initialPosition.scale > 2.9) {
            initialPosition.scale = 2.9;
        }
        this.sprite.scale.x = initialPosition.scale;
        this.sprite.scale.y = initialPosition.scale;
        // this.sprite.rotation = initialPosition.rotation;
        this.barScale = initialPosition.scale;
        this.delegate.repositionScrubber(initialPosition.scale);
        this.sprite.interactive = true;
        this.sprite.buttonMode = true;
        this.mainStage.addChild(this.sprite);
        this.delegate.showBtns();
        //render
        this.animate();
    };
    CanvasHandler.prototype.rotateStart = function (e) {
        this.rotating = true;
        this.initRotate = e.rotation;
    };
    CanvasHandler.prototype.rotateMove = function (e) {
        var currentRotation = Utils.radiansToDegrees(e.rotation);
        var pastRotation = Utils.radiansToDegrees(this.initRotate);
        var rotateDiff = currentRotation - pastRotation;
        if (currentRotation < pastRotation) {
            rotateDiff = pastRotation - currentRotation;
            var rotation = Math.abs(rotateDiff) / 2000;
            if (rotation < 1) {
                this.sprite.rotation -= rotation;
            }
        }
        else {
            var rotation = Math.abs(rotateDiff) / 2000;
            if (rotation < 1) {
                this.sprite.rotation += rotation;
            }
        }
        this.initRotate = e.rotation;
    };
    CanvasHandler.prototype.rotateStop = function (e) {
        this.rotating = false;
        this.initRotate = 0;
    };
    CanvasHandler.prototype.rotateSprite = function (deg) {
        this.sprite.rotation += deg;
    };
    //returns the initial position of the image if there was a face found in it, or not
    CanvasHandler.prototype.getInitialPosition = function (faceData) {
        var data = {
            'anchor': {
                'x': 0,
                'y': 0
            },
            'rotation': 0,
            'position': {
                'x': 0,
                'y': 0
            },
            'scale': 1
        };
        //if we have facial features, use them to position it
        if (faceData) {
            var eyeLeft = faceData.faceLandmarks.pupilLeft;
            var eyeRight = faceData.faceLandmarks.pupilRight;
            //set the anchor point
            var eyeCenter = Utils.getMidPoint(eyeLeft, eyeRight);
            data.anchor.x = eyeCenter.x / this.resizedWidth;
            data.anchor.y = eyeCenter.y / this.resizedHeight;
            //rotate based off of the angle between the pupils
            var eyeOffset = -Utils.calcAngle(eyeLeft, eyeRight) + 90;
            data.rotation = Utils.degreesToRadians(eyeOffset);
            //position the anchor on the eye line, horizontally centered
            data.position.x = this.width / 2;
            data.position.y = this.eyeY * this.posScale;
            //get the distance between the pupils, and scale
            var eyeDistance = Utils.lineDistance(eyeLeft, eyeRight);
            var scale = this.eyeWidth / eyeDistance * this.posScale;
            data.scale = scale;
        }
        else if (this.recordBoundingBox) {
            data.anchor.x = 0.5;
            data.anchor.y = 0.5;
            data.scale = 0.68;
            data.position.x = this.width / 2;
            data.position.y = this.height / 2;
        }
        else {
            // data.scale = (this.resizedWidth < this.resizedHeight) ? this.width / this.resizedWidth : this.height / this.resizedHeight;
            data.scale = 1;
            data.anchor.x = 0.5;
            data.anchor.y = 0.5;
            data.position.x = this.width / 2;
            data.position.y = this.height / 2;
        }
        return data;
    };
    return CanvasHandler;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='./CanvasHandler.ts'/>
var Editor = (function (_super) {
    __extends(Editor, _super);
    function Editor(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.maxZoomSize = 3;
        //rotate vars
        this.isRotating = false;
        this.setContent(Main.templates.find('.editContainer').clone());
        this.canvas = this.content.find('.imageCanvas');
        this.backBtn = this.content.find('.btnEditBack').on('click', function () { return _this.goBack(); });
        this.photoBtn = this.content.find('.btnUsePhoto').on('click', function () { return _this.genVideo(); });
        this.interactions = this.content.find('.interactions');
        this.faceError = this.content.find('.faceError');
        this.scrubber = this.content.find('.scrubber').on('mousedown', function (e) { return _this.startDrag(e); });
        this.progress = this.content.find('.progress');
        this.content.find('.rotate').on('mousedown', function (e) { return _this.rotateStart(e); });
        this.content.find('.rotate').on('mouseup', function () { return _this.rotateStop(); });
        this.canvasHandler = new CanvasHandler(this.content, {}, this);
    }
    Editor.prototype.loadImage = function (data) {
        if (data.type == "record") {
            this.canvasHandler.handleRecord(data);
        }
        else if (data.type == "upload") {
            this.canvasHandler.handleUpload(data.content);
        }
        else if (data.type == "url") {
            this.canvasHandler.handleURL(data.content);
        }
    };
    Editor.prototype.genVideo = function () {
        this.delegate.showOverlay();
        ga('send', 'event', 'phase_two_adjust', 'click', 'use_photo');
        EventBus.dispatch(VideoView.GENERATE_VIDEO, this.getInv());
    };
    Editor.prototype.getInv = function () {
        var img = this.canvasHandler.imageToUpload();
        var blob = Utils.dataURItoBlob(img);
        var data = {
            inventory: {
                "face_shot": blob,
                "first_name": this.delegate.firstName,
                "is_owner": Main.isOwner
            },
            phase: VideoView.PHASE_TWO,
        };
        return data;
    };
    Editor.prototype.goBack = function () {
        ga('send', 'event', 'phase_two_adjust', 'click', 'go_back');
        EventBus.dispatch(PhotoView.SHOW_PHOTO);
    };
    Editor.prototype.startDrag = function (e) {
        var _this = this;
        $(window).on('mousemove', function (e) { return _this.moveDragger(e); });
        $(window).one('mouseup touchend', function (e) { return _this.stopDragging(e); });
    };
    Editor.prototype.stopDragging = function (e) {
        $(window).off('mousemove');
    };
    Editor.prototype.moveDragger = function (e) {
        var bar = this.content.find('.track');
        var xpos = ((e.clientX - bar.offset().left) - this.scrubber.width() / 2);
        var perc = xpos / bar.width();
        if (perc < 0) {
            perc = 0;
        }
        else if (perc > 0.99) {
            perc = 0.99;
        }
        this.scrubber.css('left', perc * 100 + "%");
        this.progress.css('width', perc * 100 + "%");
        var sizePerc = this.maxZoomSize * perc;
        this.canvasHandler.resizeSprite(sizePerc);
    };
    Editor.prototype.repositionScrubber = function (deg) {
        var track = this.content.find('.track');
        var zoomPerc = (track.width() * this.maxZoomSize) / (deg * 100);
        var position = track.width() / zoomPerc;
        this.scrubber.css('left', position + "%");
        this.progress.css('width', position + "%");
    };
    Editor.prototype.showBtns = function () {
        this.backBtn.show();
        this.photoBtn.show();
        if (!this.data.isMobile) {
            this.interactions.show();
        }
    };
    Editor.prototype.rotateStart = function (e) {
        clearTimeout(this.rotateTimeout);
        this.isRotating = true;
        this.rotateImage(e.target.className);
    };
    Editor.prototype.rotateImage = function (directon) {
        var _this = this;
        if (this.isRotating) {
            var deg = 0.1;
            if (directon == "right") {
                deg = -0.1;
            }
            this.canvasHandler.rotateSprite(deg);
            clearTimeout(this.rotateTimeout);
            this.rotateTimeout = setTimeout(function () {
                _this.rotateImage(directon);
            }, 50);
        }
        else {
            clearTimeout(this.rotateTimeout);
        }
    };
    Editor.prototype.rotateStop = function () {
        this.isRotating = false;
    };
    Editor.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        this.canvasHandler.clearCanvas();
        this.backBtn.hide();
        this.photoBtn.hide();
        this.faceError.hide();
        this.delegate.hideOverlay();
        if (!this.data.isMobile) {
            this.interactions.hide();
        }
    };
    return Editor;
}(Component));
var RecordHandler = (function () {
    function RecordHandler(content, del) {
        var _this = this;
        this.width = 640;
        this.height = 480;
        this.attached = false;
        this.isUsingFlash = false;
        this.content = content;
        this.videoDiv = this.content.find('#video');
        this.delegate = del;
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        var isIE = false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        if (isSafari || isIE && !isEdge) {
            this.isUsingFlash = true;
            if (isSafari) {
                this.width = window.innerWidth;
                this.height = window.innerHeight;
            }
        }
        this.canvas = document.createElement("canvas");
        this.canvas.width = this.width;
        this.canvas.height = this.height;
        this.ctx = this.canvas.getContext("2d");
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.image = this.ctx.getImageData(0, 0, this.width, this.height);
        Webcam.set({
            width: this.width,
            height: this.height,
            dest_width: this.width,
            dest_height: this.height,
            image_format: 'jpeg',
            jpeg_quality: 100,
            flip_horiz: true,
            force_flash: this.isUsingFlash,
            constraints: { width: { min: this.width }, height: { min: this.height } }
        });
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
    }
    RecordHandler.prototype.init = function () {
        var _this = this;
        if (this.isUsingFlash) {
            this.delegate.hideOverlay();
            this.content.find('.record').css('background-color', 'white');
        }
        Webcam.on('live', function (e) {
            _this.delegate.showOverlay();
            if (_this.isUsingFlash) {
                _this.content.find('.record').css('background-color', 'black');
            }
        });
        Webcam.on('error', function (err) {
            console.error(err);
            _this.delegate.camError();
        });
        this.attached = true;
        Webcam.attach('#video');
        this.resize();
    };
    RecordHandler.prototype.resetState = function () {
        if (this.attached) {
            Webcam.reset();
            this.attached = false;
        }
    };
    RecordHandler.prototype.stopCapture = function () {
        var _this = this;
        Webcam.snap(function (data_uri) {
            _this.dataUri = data_uri;
            _this.createInventory();
        });
    };
    RecordHandler.prototype.createInventory = function () {
        var _this = this;
        var img = new Image();
        img.onload = function () {
            _this.ctx.drawImage(img, 0, 0, _this.width, _this.height);
            var inv = _this.canvas.toDataURL('image/jpeg');
            _this.delegate.imageLoaded(inv);
        };
        img.src = this.dataUri;
    };
    RecordHandler.prototype.resize = function () {
        if (!this.isUsingFlash) {
            //make the player fullscreen
            var pos = Utils.fitToContainer({
                'containerWidth': window.innerWidth,
                'containerHeight': window.innerHeight,
                'contentWidth': this.width,
                'contentHeight': this.height,
                'vAlign': 'top',
                'hAlign': 'center',
                'scaleMode': 'proportionalOutside'
            });
            this.videoDiv.css(pos);
        }
        else {
            var pos_1 = {
                'width': this.width,
                'height': this.height,
                'position': 'absolute',
                'margin': 'auto',
                'left': '0',
                'right': '0',
                'top': '0',
                'bottom': '0'
            };
            this.videoDiv.css(pos_1);
        }
    };
    return RecordHandler;
}());
var CountDown = (function () {
    function CountDown(content, del) {
        this.count = 1;
        this.content = content;
        this.del = del;
        this.one = this.content.find('.one');
        this.two = this.content.find('.two');
        this.three = this.content.find('.three');
        this.number = this.content.find('.number');
    }
    CountDown.prototype.show = function () {
        var _this = this;
        this.content.show();
        clearInterval(this.countDownTimer);
        this.countDownTimer = setInterval(function () { _this.countDown(); }, 1000);
    };
    CountDown.prototype.countDown = function () {
        var _this = this;
        this.number.removeClass('highlighted');
        if (this.count == 1) {
            this.one.addClass('highlighted');
        }
        else if (this.count == 2) {
            this.two.addClass('highlighted');
        }
        else if (this.count == 3) {
            this.three.addClass('highlighted');
            clearInterval(this.countDownTimer);
            setTimeout(function () {
                _this.del.takePhoto();
                _this.hide();
            }, 500);
        }
        this.count++;
    };
    CountDown.prototype.hide = function () {
        this.count = 1;
        clearInterval(this.countDownTimer);
        this.content.hide();
        this.number.removeClass('highlighted');
    };
    return CountDown;
}());
/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='./RecordHandler.ts'/>
/// <reference path='./CountDown.ts'/>
var Record = (function (_super) {
    __extends(Record, _super);
    function Record(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.holeWidth = 540;
        this.holeHeight = 700;
        this.setContent(Main.templates.find('.recordWrap').clone());
        this.captureBtn = this.content.find('.btnCapture').on('click', function () { return _this.startCapture(); });
        this.backBtn = this.content.find('.btnRecordBack').on('click', function () { return _this.goBack(); });
        this.videoDiv = this.content.find('video');
        this.flash = this.content.find('.flash');
        this.overlay = this.content.find('.guideOverlay');
        this.instructions = this.content.find('.facePrompt');
        this.recordHandler = new RecordHandler(this.content, this);
        this.countDown = new CountDown(this.content.find('.countDownHolder'), this);
        this.resize();
    }
    Record.prototype.showOverlay = function () {
        this.overlay.show();
    };
    Record.prototype.hideOverlay = function () {
        this.overlay.hide();
    };
    Record.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        this.recordHandler.init();
        this.captureBtn.show();
        this.container.scrollTop(0);
        this.container.css('overflow', 'hidden');
    };
    Record.prototype.startCapture = function () {
        this.captureBtn.hide();
        this.instructions.hide();
        this.countDown.show();
        ga('send', 'event', 'phase_two_record', 'click', 'take_photo');
    };
    Record.prototype.goBack = function () {
        this.instructions.show();
        this.countDown.hide();
        EventBus.dispatch(PhotoView.SHOW_PHOTO);
        ga('send', 'event', 'phase_two_adjust', 'click', 'go_back');
    };
    Record.prototype.takePhoto = function () {
        var _this = this;
        this.flash.addClass('pluse');
        this.recordHandler.stopCapture();
        ga('send', 'event', 'phase_two_record', 'click', 'photo_taken');
        setTimeout(function () {
            _this.flash.removeClass('pluse');
        }, 150);
    };
    Record.prototype.imageLoaded = function (inv) {
        inv['first_name'] = this.delegate.firstName;
        var box = {
            'x': (window.innerWidth - this.holeWidth) / 2,
            'y': (window.innerHeight - this.holeWidth) / 2,
            'width': this.holeWidth,
            'height': this.holeHeight
        };
        var data = {
            'box': box,
            'type': 'record',
            'content': inv
        };
        EventBus.dispatch(PhotoView.CHECK_DATA, data);
    };
    Record.prototype.camError = function () {
        EventBus.dispatch(PhotoView.SHOW_PHOTO);
    };
    Record.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        this.recordHandler.resetState();
        this.container.css('overflow', 'auto');
    };
    Record.prototype.resize = function () {
        // let width = window.innerWidth;
        // let height = window.innerHeight;
        // if(this.videoDiv){
        //     let pos = Utils.fitToContainer({
        //         'containerWidth':width,
        //         'containerHeight':height,
        //         'contentWidth':width,
        //         'contentHeight':height,
        //         'scaleMode':'proportionalInside',
        //         'hAlign':'center',
        //         'vAlign':'center'
        //     });
        //     this.videoDiv.css({'left':pos.left, 'width':pos.width, 'top':pos.top});
        // }
    };
    return Record;
}(Component));
/// <reference path='../scaffolding/component.ts'/>
var Photo = (function (_super) {
    __extends(Photo, _super);
    function Photo(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.hasBadWords = false;
        this.fpo = false;
        this.fpoImage = "https://s3.amazonaws.com/imposium-dev-barkleys/tracy.jpg";
        this.setContent(Main.templates.find('.photo').clone());
        this.inputName = this.content.find('.name');
        this.inputWrap = this.content.find('.inputWrap');
        this.inputName.on('focus', function (e) { return _this.removeError(e); });
        this.inputName.on('input paste', function (e) { return _this.checkInput(e, '^[a-zA-Z \s$-]+$', 'nameString'); });
        this.uploadBtn = this.content.find('.btnUpload').on('click', function () { return _this.pickImage(); });
        this.recordBtn = this.content.find('.btnPhoto').on('click', function () { return _this.recordImage(); });
        this.btnContainer = this.content.find('.btns');
        this.imageInput = this.content.find('.imageInput').on('change', function () { return _this.photoSelected(); });
        this.inputError = this.content.find('.inputError');
        this.photoError = this.content.find('.photoError');
        this.mobileheader = this.content.find('.mobileHeader');
        this.desktopheader = this.content.find('.deskHeader');
        this.content.on('scroll', function () { return _this.blurInput(); });
        EventBus.addEventListener(Photo.PHOTO_ERROR, function () { return _this.showPhotoError(); }, this);
        EventBus.addEventListener(Photo.PHOTO_PASSED, function () { return _this.photoPassed(); }, this);
        this.termsBox = this.content.find('.terms').on('click', function () { return _this.termsToggle(); });
        this.content.find('.noCam').hide();
    }
    Photo.prototype.showMe = function () {
        var _this = this;
        _super.prototype.showMe.call(this);
        var hasFlash = false;
        var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > 0;
        var isIE = false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
        var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > 0;
        navigator.getUserMedia = (navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);
        this.mobileheader.hide();
        if (Main.mobile) {
            this.content.on("touchmove", function () { return _this.blurInput(); });
            this.recordBtn.hide();
            this.content.find('.cam').hide();
            this.uploadBtn.html('Continue');
            this.mobileheader.show();
            this.desktopheader.hide();
        }
        else {
            if (isSafari && !isChrome || isIE || isEdge) {
                hasFlash = ((typeof navigator.plugins != "undefined" && typeof navigator.plugins["Shockwave Flash"] == "object") || (window.ActiveXObject && (new ActiveXObject("ShockwaveFlash.ShockwaveFlash")) != false));
                if (hasFlash) {
                    this.recordBtn.show();
                }
                else {
                    this.hideTakePhotoBtn();
                }
            }
            else {
                navigator.getUserMedia({ audio: false, video: true }, function (e) { return _this.mediaSuccess(e); }, function (e) { return _this.mediaError(e); });
            }
            $(window).bind('resize orientationchange', function () { return _this.resize(); });
        }
        if (VideoView.VIN) {
            this.inputWrap.hide();
            this.inputError.hide();
            this.photoError.hide();
        }
        else {
            this.inputWrap.show();
        }
        this.isShowing = true;
        if (!Main.mobile) {
            this.resize();
        }
    };
    Photo.prototype.mediaError = function (e) {
        this.hideTakePhotoBtn();
    };
    Photo.prototype.hideTakePhotoBtn = function () {
        this.recordBtn.hide();
        this.content.find('.cam').hide();
        this.content.find('.noCam').show();
    };
    Photo.prototype.mediaSuccess = function (e) {
        this.recordBtn.show();
        var track = e.getTracks()[0]; // if only one media track
        track.stop();
        this.resize();
    };
    Photo.prototype.blurInput = function () {
        this.inputName.blur();
    };
    Photo.prototype.termsToggle = function () {
        if (this.termsBox.hasClass('checked')) {
            this.termsBox.removeClass('checked');
            this.btnContainer.addClass('disabled');
        }
        else {
            this.termsBox.addClass('checked');
            this.btnContainer.removeClass('disabled');
        }
    };
    Photo.prototype.recordImage = function () {
        ga('send', 'event', 'phase_two_input', 'click', 'take_photo');
        if (!VideoView.VIN && this.inputName.val().replace(/\s/g, '').length != 0) {
            this.moderateText("record");
            this.delegate.firstName = this.inputName.val();
        }
        else if (VideoView.VIN) {
            EventBus.dispatch(PhotoView.SHOW_RECORD);
        }
        else {
            this.showError();
            this.inputName.addClass('error');
        }
    };
    Photo.prototype.pickImage = function () {
        if (!this.fpo && !VideoView.VIN && this.inputName.val().replace(/\s/g, '').length != 0) {
            this.imageInput.click();
        }
        else if (this.fpo) {
            var data = {
                'type': 'url',
                'content': this.fpoImage
            };
        }
        else if (VideoView.VIN) {
            this.imageInput.click();
        }
        else {
            this.showError();
            this.inputName.addClass('error');
        }
    };
    Photo.prototype.moderateText = function (type) {
        var _this = this;
        var name = this.inputName.val().toLowerCase();
        this.hasBadWords = false;
        var url = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text=' + name;
        $.ajax({
            type: 'GET',
            url: url,
            dataType: 'jsonp',
            success: function (data) {
                var badwords = data.rsp.found;
                if (badwords == 1) {
                    _this.hasBadWords = true;
                }
                if (type != "record") {
                    _this.moderateImage();
                }
                else {
                    if (!_this.hasBadWords) {
                        _this.delegate.firstName = _this.inputName.val();
                        EventBus.dispatch(PhotoView.SHOW_RECORD);
                    }
                    else {
                        _this.showError();
                    }
                }
            },
            error: function (e, s, m) { }
        });
    };
    Photo.prototype.moderateImage = function () {
        EventBus.dispatch(PhotoView.CHECK_DATA, this.uploadData);
    };
    Photo.prototype.checkInput = function (evt, r, field) {
        var regex = new RegExp(r);
        var input = $(evt.target);
        if (evt.type == "input") {
            var val = input.val();
            if (regex.test(val) || val === "") {
                this.inputError.css('display', 'none');
                this[field] = val;
            }
            else {
                input.val(this[field]);
            }
        }
        else if (evt.type == "paste") {
            var paste = evt.originalEvent.clipboardData.getData('Text');
            if (!regex.test(paste)) {
                return false;
            }
            else {
                this.inputError.css('display', 'none');
                return true;
            }
        }
    };
    Photo.prototype.photoSelected = function () {
        var convertInput = this.imageInput;
        if (this.imageInput.val().length > 1) {
            if (!VideoView.VIN) {
                this.delegate.firstName = this.inputName.val();
            }
            var data = {
                'type': 'upload',
                'content': convertInput[0].files[0]
            };
            this.uploadData = data;
            ga('send', 'event', 'phase_two_input', 'click', 'upload_photo');
            if (!VideoView.VIN) {
                this.moderateText("editor");
            }
            else {
                this.moderateImage();
            }
        }
        this.imageInput.val("");
    };
    Photo.prototype.showError = function () {
        this.inputError.css('display', 'block');
    };
    Photo.prototype.showPhotoError = function () {
        this.delegate.hideOverlay();
        if (this.hasBadWords) {
            this.showError();
        }
        this.photoError.css('display', 'block');
    };
    Photo.prototype.photoPassed = function () {
        if (!this.hasBadWords) {
            EventBus.dispatch(PhotoView.SHOW_EDITOR, this.uploadData);
        }
        else {
            this.delegate.hideOverlay();
            this.showError();
        }
    };
    Photo.prototype.removeError = function (e) {
        this.inputName.removeClass('error');
        this.inputError.css('display', 'none');
    };
    Photo.prototype.resize = function () {
        if (this.isShowing) {
            var h = window.innerHeight;
            if (h > 768) {
                this.container.scrollTop(0);
            }
        }
    };
    Photo.prototype.hideMe = function () {
        var _this = this;
        _super.prototype.hideMe.call(this);
        if (Main.mobile) {
            this.content.off("touchmove", function () { return _this.blurInput(); });
        }
        this.isShowing = false;
        this.inputError.css('display', 'none');
        this.photoError.css('display', 'none');
        this.termsBox.removeClass('checked');
        this.btnContainer.addClass('disabled');
        this.inputName.removeClass('error');
    };
    Photo.PHOTO_ERROR = "photoerror";
    Photo.PHOTO_PASSED = "photopassed";
    return Photo;
}(Component));
/// <reference path='../scaffolding/view.ts'/>
/// <reference path='../components/Editor.ts'/>
/// <reference path='../components/Record.ts'/>
/// <reference path='../components/Photo.ts'/>
var PhotoView = (function (_super) {
    __extends(PhotoView, _super);
    function PhotoView(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.editorShowing = false;
        this.setContent(Main.templates.find('.photoWrap').clone());
        EventBus.addEventListener(PhotoView.SHOW_EDITOR, function () { return _this.showEditor(); }, this);
        EventBus.addEventListener(PhotoView.SHOW_RECORD, function () { return _this.showRecord(); }, this);
        EventBus.addEventListener(PhotoView.SHOW_PHOTO, function () { return _this.showPhoto(); }, this);
        EventBus.addEventListener(PhotoView.CHECK_DATA, function (data) { return _this.checkData(data); }, this);
        this.photo = new Photo(this.content, {}, this);
        this.editor = new Editor(this.content, {}, this);
        this.record = new Record(this.content, {}, this);
        this.loadOverlay = this.content.find('.loadingOverlay');
    }
    PhotoView.prototype.setRoutes = function () {
        var _this = this;
        page(this.data.route, function () {
            EventBus.dispatch(View.HIDE_ACTIVE);
            _this.showMe();
        });
        page('/owner', function () {
            Main.isOwner = true;
            EventBus.dispatch(View.HIDE_ACTIVE);
            _this.showMe();
        });
    };
    PhotoView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        this.showPhoto();
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Video2);
    };
    PhotoView.prototype.hideComponents = function () {
        this.photo.hideMe();
        this.record.hideMe();
        this.editor.hideMe();
    };
    PhotoView.prototype.showEditor = function () {
        if (!this.editorShowing) {
            this.hideComponents();
            this.editor.showMe();
            this.editorShowing = true;
            this.hideOverlay();
        }
    };
    PhotoView.prototype.checkData = function (data) {
        if (data.type == "record") {
            this.showEditor();
        }
        this.editorShowing = false;
        this.editor.loadImage(data);
        this.showOverlay();
    };
    PhotoView.prototype.showRecord = function () {
        this.hideComponents();
        this.record.showMe();
    };
    PhotoView.prototype.showPhoto = function () {
        this.hideComponents();
        this.photo.showMe();
        this.hideOverlay();
    };
    PhotoView.prototype.showOverlay = function () {
        this.loadOverlay.removeClass('hide');
    };
    PhotoView.prototype.hideOverlay = function () {
        this.loadOverlay.addClass('hide');
    };
    PhotoView.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        this.showPhoto();
    };
    PhotoView.SHOW_PHOTO = "showphoto";
    PhotoView.SHOW_EDITOR = "showeditor";
    PhotoView.SHOW_RECORD = "showrecord";
    PhotoView.CHECK_DATA = "checkdata";
    return PhotoView;
}(View));
/// <reference path='./View.ts'/>
/// <reference path='./EventBus.ts'/>
/// <reference path='../views/PhotoView.ts'/>
var DynamicVideoView = (function (_super) {
    __extends(DynamicVideoView, _super);
    function DynamicVideoView(content, data, del) {
        var _this = this;
        _super.call(this, content, data, del);
        this.isReload = false;
        this.deeplink = true;
        var key = 'Kahrongorie8wuo3oorefuv7iesh4yo4';
        this.imposium = new Imposium.ImposiumClient(key);
        this.imposium.on(Imposium.events.STATUS, function (e) { return _this.onStatusUpdate(e); }, this);
    }
    DynamicVideoView.prototype.setRoutes = function () {
        var _this = this;
        page(this.data.route + '/:phase?/:expId?/:owner?', function (req) {
            var phase = req.params.phase;
            var expId = req.params.expId;
            if (expId && phase) {
                //EXPERIENCE
                if (expId.length == 36) {
                    //If Phase One URL, redirect to info page
                    if (phase == VideoView.PHASE_ONE) {
                        page('/info');
                        return;
                    }
                    //set config
                    Main.isOwner = (req.params.owner) ? true : false;
                    VideoView.PHASE = phase;
                    _this.experienceId = expId;
                    //load the video
                    EventBus.dispatch(DynamicVideoView.PHASE_SET);
                    _this.loadVideo();
                }
                else if (expId.length == 17) {
                    page('/info');
                    return;
                }
                else {
                    _this.noVideo();
                }
            }
            else {
                _this.noVideo();
            }
            EventBus.dispatch(View.HIDE_ACTIVE);
            _this.showMe();
        });
    };
    DynamicVideoView.prototype.getStoryId = function () {
        //return a story id from a bucket of story id's based off of phase and owner
        var bucket = (Main.isOwner) ? this.data[VideoView.PHASE + '_owner'] : this.data[VideoView.PHASE];
        var id = bucket[Utils.randomFromInterval(0, bucket.length - 1)];
        return id;
    };
    DynamicVideoView.prototype.createExperience = function () {
        var inventory = this.getInventory();
        this.imposium.createExperience(this.storyId, inventory, false, $.proxy(this.experienceCreated, this), $.proxy(this.experienceError, this));
    };
    DynamicVideoView.prototype.experienceCreated = function (expData) {
        this.deeplink = false;
        var route = this.data.route + '/' + VideoView.PHASE + '/' + expData.id;
        if (Main.isOwner) {
            route += "/1";
        }
        page(route);
    };
    DynamicVideoView.prototype.loadVideo = function () {
        var job = {
            expId: this.experienceId,
            sceneId: 'cc757eea-ded7-49de-a189-5305d9367358',
            actId: '17aad47e-63ab-45c9-b3a9-8e3168f2678c'
        };
        this.imposium.getVideo(job, $.proxy(this.gotScene, this), $.proxy(this.eventProcessorError, this));
    };
    DynamicVideoView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        this.deeplink = true;
    };
    DynamicVideoView.prototype.experienceError = function (e) {
        console.error("Experience Error");
        console.error(e);
    };
    DynamicVideoView.prototype.eventProcessorError = function (e) {
        console.error("Event Processor Error");
        console.error(e);
    };
    DynamicVideoView.PHASE_SET = "phaseset";
    return DynamicVideoView;
}(View));
var Loader = (function (_super) {
    __extends(Loader, _super);
    function Loader(container, data, del) {
        var _this = this;
        _super.call(this, container, data, del);
        this.cyclingText = false;
        this.cycleCount = 0;
        this.linesCount = 0;
        this.cycleDelay = 7000;
        this.startNum = 0;
        this.setContent(Main.templates.find('.loader').clone());
        this.loaderCircle = this.content.find('#fill-circle');
        this.loadPerc = this.content.find('.loadPerc');
        this.loaderDiv = this.content.find('.loader');
        this.circleDiv = $(this.loaderCircle[0]);
        $(window).on('resize orientationchange', function () { return _this.resize(); });
        this.resize();
    }
    Loader.prototype.updatePercentage = function (perc) {
        if (!this.cyclingText) {
            this.cycleLoadText();
            this.cyclingText = true;
            clearTimeout(this.progrossTimeout);
            this.progressCircle(perc);
        }
        if (perc == 1) {
            clearTimeout(this.progrossTimeout);
            this.progressCircle(perc);
        }
    };
    Loader.prototype.progressCircle = function (perc) {
        var _this = this;
        var percNum = Math.floor(this.startNum * 100);
        if (percNum < 10 && percNum != 0) {
            percNum = "0" + percNum + "%";
        }
        else {
            percNum = percNum + "%";
        }
        this.loadPerc.html(percNum);
        this.startNum = this.startNum + 0.01;
        var nPerc = this.startNum;
        if (nPerc < 1) {
            this.progrossTimeout = setTimeout(function () { _this.progressCircle(nPerc); }, 800);
        }
        if (!Main.isIE) {
            this.circleDiv.attr('class', 'animate');
        }
        else {
            var num = Math.floor(720 * perc);
            this.loaderCircle.css('stroke-dasharray', num + ',2000');
        }
        if (perc == 1) {
            this.loadPerc.html("100%");
            this.startNum = 0;
            var delay = 500;
            if (Main.mobile) {
                delay = 0;
            }
            if (!Main.isIE) {
                this.circleDiv.attr('class', '');
                this.circleDiv.attr('class', 'complete');
            }
            else {
                this.loaderCircle.css('stroke-dasharray', '725,2000');
            }
            setTimeout(function () { _this.hideMe(); }, delay);
        }
    };
    Loader.prototype.reset = function () {
        this.circleDiv.attr('class', '');
    };
    Loader.prototype.cycleLoadText = function () {
        var _this = this;
        if (VideoView.PHASE == VideoView.PHASE_ONE) {
            this.cycleTextHolder = this.content.find('.copy');
        }
        else {
            this.cycleTextHolder = this.content.find('.copyPT');
        }
        this.cycleTextHolder.show();
        this.cycleText = $(this.cycleTextHolder).find('.line');
        this.linesCount = this.cycleText.length;
        setTimeout(function () { _this.beginCycling(); }, 500);
    };
    Loader.prototype.beginCycling = function () {
        var _this = this;
        this.cycleText.addClass('hide');
        var textToShow = this.cycleTextHolder[0].children[this.cycleCount];
        $(textToShow).removeClass('hide');
        this.cycleCount++;
        if (this.cycleCount == this.linesCount) {
            this.cycleCount = 0;
        }
        clearTimeout(this.cycleTimeout);
        this.cycleTimeout = setTimeout(function () { _this.beginCycling(); }, this.cycleDelay);
    };
    Loader.prototype.resetCycleText = function () {
        clearTimeout(this.cycleTimeout);
        this.cycleCount = 0;
        if (this.cycleText) {
            this.cycleText.addClass('hide');
        }
    };
    Loader.prototype.resize = function () {
        var sHeight = $(window).height();
        var headerHeight = $('.header').height();
        this.content.find('.circle').css('top', (sHeight / 2) + headerHeight);
    };
    Loader.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        this.resize();
    };
    Loader.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        clearTimeout(this.progrossTimeout);
        clearTimeout(this.cycleTimeout);
        this.resetCycleText();
        this.cyclingText = false;
        this.cycleCount = 0;
    };
    return Loader;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
var Success = (function (_super) {
    __extends(Success, _super);
    function Success(container, data, delegate) {
        _super.call(this, container, data, delegate);
        this.setContent(Main.templates.find('.successWrap').clone());
        this.hideDelay = 500;
    }
    return Success;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
var Thanks = (function (_super) {
    __extends(Thanks, _super);
    function Thanks(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.phase = "phase_two_thanks";
        this.setContent(Main.templates.find('.thanksWrap').clone());
        this.btnRewatch = this.content.find('.btnRewatch').on('click', function () { return _this.rewatch(); });
        this.btnCreate = this.content.find('.btnCreate').on('click', function () { return _this.create(); });
        EventBus.addEventListener(DynamicVideoView.PHASE_SET, function () { return _this.setPhase(); }, this);
        this.content.find('.facebook').bind('click', function (e) { return _this.delegate.shareFacebook(e); });
        this.content.find('.twitter').bind('click', function (e) { return _this.delegate.shareTwitter(e); });
        this.content.find('.mailLink').bind('click', function (e) { return _this.delegate.shareEmail(e); });
        this.content.find('.download').bind('click', function (e) { return _this.delegate.shareDownload(e); });
        EventBus.addEventListener(DynamicVideoView.PHASE_SET, function () { return _this.setPhase(); }, this);
        this.setPhase();
        this.hideDelay = 500;
    }
    Thanks.prototype.setPhase = function () {
        if (VideoView.PHASE == VideoView.PHASE_ONE) {
            this.btnCreate.html("Edit/create another");
            this.phase = "phase_one_thanks";
        }
    };
    Thanks.prototype.rewatch = function () {
        this.hideMe();
        ga('send', 'event', this.phase, 'click', 'rewatch');
        EventBus.dispatch(VideoPlayer.RESET);
        EventBus.dispatch(VideoPlayer.SHOW);
        EventBus.dispatch(VideoPlayer.PLAY);
    };
    Thanks.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Thanks);
    };
    Thanks.prototype.create = function () {
        VideoView.VIN = "";
        Main.isOwner = false;
        VideoView.VIN = "";
        var route = (!Main.phaseTwoLive && VideoView.PHASE == VideoView.PHASE_TWO) ? '/photo' : '/info';
        ga('send', 'event', this.phase, 'click', 'create_more');
        page(route);
    };
    return Thanks;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
var VideoControls = (function (_super) {
    __extends(VideoControls, _super);
    function VideoControls(container, data, delegate) {
        _super.call(this, container, data, delegate);
        this.duration = 0;
        this.currentTime = 0;
        this.barWidth = 395;
        this.dragging = false;
        this.isPlaying = false;
        this.isMuted = false;
        this.dragOffset = 0;
        this.setContent(Main.templates.find('.videoControls').clone());
        this.bindEvents();
    }
    VideoControls.prototype.bindEvents = function () {
        var _this = this;
        //buttons
        this.btnPlay = this.content.find('.btnPlay').bind('click', function () { return _this.playClicked(); });
        this.btnPause = this.content.find('.btnPause').bind('click', function () { return _this.pauseClicked(); });
        this.btnMute = this.content.find('.btnMute').bind('click', $.proxy(this.toggleMute, this));
        this.btnUnmute = this.content.find('.btnUnmute').bind('click', $.proxy(this.toggleMute, this));
        this.btnEnterFS = this.content.find('.btnEnterFullscreen').bind('click', function () { return _this.toggleFullscreen(); });
        this.btnExitFS = this.content.find('.btnExitFullscreen').bind('click', function () { return _this.toggleFullscreen(); });
        this.progress = this.content.find('.progress').bind('click', $.proxy(this.skipTo, this));
        this.videoDiv = this.data.video;
        this.dragger = this.content.find('.dragger').on('mousedown touchstart', function (e) { return _this.startDragging(e); });
        this.bar = this.content.find('.bar');
        $(this.videoDiv).on('play', function () { return _this.videoPlaying(); });
        $(this.videoDiv).on('pause', function () { return _this.videoPaused(); });
        $(this.videoDiv).on('ended', function () { return _this.videoOver(); });
        $(this.videoDiv).on('loadedmetadata', function () { return _this.setDuration(); });
        $(document).on('webkitfullscreenchange mozfullscreenchange fullscreenchange MSFullscreenChange', function () { return _this.fullscreenchange(); });
    };
    VideoControls.prototype.setDuration = function () {
        this.duration = this.videoDiv.duration;
    };
    VideoControls.prototype.playClicked = function () {
        EventBus.dispatch(VideoControls.PLAY);
    };
    VideoControls.prototype.pauseClicked = function () {
        EventBus.dispatch(VideoControls.PAUSE);
    };
    VideoControls.prototype.videoPlaying = function () {
        this.isPlaying = true;
        this.btnPause.show();
        this.btnPlay.hide();
        clearInterval(this.playInterval);
        this.playInterval = setInterval($.proxy(this.checkTime, this), 33);
    };
    VideoControls.prototype.videoPaused = function () {
        this.isPlaying = false;
        this.btnPlay.show();
        this.btnPause.hide();
        clearInterval(this.playInterval);
    };
    //mutes the video and shows / hides the correct UI
    VideoControls.prototype.toggleMute = function () {
        if (this.isMuted) {
            this.btnMute.show();
            this.btnUnmute.hide();
            this.videoDiv.volume = 1;
            this.isMuted = false;
        }
        else {
            this.btnMute.hide();
            this.btnUnmute.show();
            this.videoDiv.volume = 0;
            this.isMuted = true;
        }
    };
    VideoControls.prototype.videoOver = function () {
        this.isPlaying = false;
        this.btnPause.hide();
        this.btnPlay.show();
        clearInterval(this.playInterval);
    };
    //checks the video time and updates the progress bar
    VideoControls.prototype.checkTime = function () {
        this.currentTime = this.videoDiv.currentTime;
        var percentDone = this.currentTime / this.duration * 100;
        if (!this.dragging) {
            this.bar.css('width', percentDone + '%');
            this.dragger.css('left', percentDone + '%');
        }
    };
    VideoControls.prototype.startDragging = function (e) {
        var _this = this;
        e.preventDefault();
        e.stopPropagation();
        this.dragging = true;
        this.dragOffset = (e.clientX - this.dragger.offset().left) - (this.dragger.width() / 2);
        $(window).on('mousemove', function (e) { return _this.moveDragger(e); });
        $(window).one('mouseup', function (e) { return _this.stopDragging(e); });
    };
    VideoControls.prototype.stopDragging = function (e) {
        e.preventDefault();
        e.stopPropagation();
        this.dragging = false;
        $(window).off('mousemove');
    };
    VideoControls.prototype.moveDragger = function (e) {
        e.preventDefault();
        e.stopPropagation();
        //get the position of the dragger
        var xpos = e.clientX - this.progress.offset().left - this.dragOffset;
        xpos = Math.max(xpos, 0);
        xpos = Math.min(xpos, this.barWidth);
        //set the time of the video
        var perc = xpos / this.barWidth * 100;
        this.setTimeFromPercentage(perc);
    };
    VideoControls.prototype.setTimeFromPercentage = function (perc) {
        var time = this.duration * perc / 100;
        EventBus.dispatch(VideoControls.NAVIGATE_TO, time);
        this.currentTime = time;
        var l = (this.barWidth * perc / 100);
        this.dragger.css('left', l + 'px');
        this.bar.css('width', l + 'px');
    };
    //jumps to a specific point in the video
    VideoControls.prototype.skipTo = function (e) {
        var xpos = e.clientX - this.progress.offset().left;
        var perc = xpos / this.barWidth * 100;
        this.setTimeFromPercentage(perc);
    };
    //called when we exit fullscreen    
    VideoControls.prototype.exitFullscreen = function () {
        this.btnEnterFS.show();
        this.btnExitFS.hide();
        EventBus.dispatch(VideoControls.EXIT_FULLSCREEN);
    };
    //called when we enter fullscreen
    VideoControls.prototype.enterFullscreen = function () {
        this.btnEnterFS.hide();
        this.btnExitFS.show();
        EventBus.dispatch(VideoControls.ENTER_FULLSCREEN);
    };
    //called when the browser fullscreen change event fires
    VideoControls.prototype.fullscreenchange = function () {
        var doc = window.document;
        if (!doc["fullscreenElement"] && !doc["mozFullScreenElement"] && !doc["webkitFullscreenElement"] && !doc["msFullscreenElement"]) {
            this.exitFullscreen();
        }
    };
    //either enters or exits fullscreen mode
    VideoControls.prototype.toggleFullscreen = function () {
        var doc = window.document;
        var docEl = $("#container")[0];
        var requestFullScreen = docEl["requestFullscreen"] || docEl["mozRequestFullScreen"] || docEl["webkitRequestFullScreen"] || docEl["msRequestFullscreen"];
        var cancelFullScreen = doc["exitFullscreen"] || doc["mozCancelFullScreen"] || doc["webkitExitFullscreen"] || doc["msExitFullscreen"];
        if (!doc["fullscreenElement"] && !doc["mozFullScreenElement"] && !doc["webkitFullscreenElement"] && !doc["msFullscreenElement"]) {
            requestFullScreen.call(docEl);
            this.enterFullscreen();
        }
        else {
            cancelFullScreen.call(doc);
            this.exitFullscreen();
        }
    };
    VideoControls.ENTER_FULLSCREEN = "enterfullscreen";
    VideoControls.EXIT_FULLSCREEN = "exitfullscreen";
    VideoControls.NAVIGATE_TO = "navigatetotime";
    VideoControls.PAUSE = "pauseclicked";
    VideoControls.PLAY = "playclicked";
    return VideoControls;
}(Component));
/// <reference path='../scaffolding/Utils.ts'/>
/// <reference path='../scaffolding/EventBus.ts'/>
/// <reference path='./VideoControls.ts'/>
var VideoPlayer = (function (_super) {
    __extends(VideoPlayer, _super);
    function VideoPlayer(container, data, id) {
        var _this = this;
        _super.call(this, container, data, id);
        this.vidWidth = 1280;
        this.vidHeight = 720;
        this.maxWidth = 1920;
        this.gaFired = 0;
        this.isFullscreen = false;
        this.isPlaying = false;
        this.gaPhase = 'phase_one_video';
        this.setContent(Main.templates.find('.videoPlayer').clone());
        this.video = this.content.find('.player')[0];
        this.player = this.content.find('.player');
        this.videoOptions = this.content.find('.videoOptions');
        this.thanksMobile = this.content.find('.thanksMobile');
        this.btnCreate = this.content.find('.btnCreate').on('click', function () { return _this.createAnother(); });
        this.content.find('.facebook').bind('click', function (e) { return _this.delegate.shareFacebook(e); });
        this.content.find('.twitter').bind('click', function (e) { return _this.delegate.shareTwitter(e); });
        this.content.find('.mailLink').bind('click', function (e) { return _this.delegate.shareEmail(e); });
        this.content.find('.download').bind('click', function (e) { return _this.delegate.shareDownload(e); });
        EventBus.addEventListener(VideoPlayer.SHOW, function () { return _this.showMe(); }, this);
        EventBus.addEventListener(VideoPlayer.RESET, function () { return _this.reset(); }, this);
        EventBus.addEventListener(VideoPlayer.PLAY, function () { return _this.playVideo(); }, this);
        EventBus.addEventListener(DynamicVideoView.PHASE_SET, function () { return _this.setPhase(); }, this);
        this.video.addEventListener("ended", function () {
            if (Main.mobile) {
                _this.thanksMobile.removeClass('hide');
                if (VideoView.PHASE == VideoView.PHASE_TWO) {
                    _this.video.setAttribute("poster", "/img/video_poster_two.jpg");
                }
                else {
                    _this.video.setAttribute("poster", "/img/video_poster.jpg");
                }
                _this.video.currentTime = 0;
                _this.video.load();
            }
            else {
                ga('send', 'event', _this.gaPhase, 'playback', 'progress', 1);
                ga('send', 'event', _this.gaPhase, 'progress', 'complete');
                _this.dispatch(VideoPlayer.VIDEO_COMPLETE);
            }
        });
        if (!Main.mobile) {
            this.videoControls = new VideoControls(this.content.find('.controlContainer'), {
                'video': this.video
            }, this);
            EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, function () { return _this.enterFullscreen(); }, this);
            EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, function () { return _this.exitFullscreen(); }, this);
            EventBus.addEventListener(VideoControls.PAUSE, function () { return _this.pauseVideo(); }, this);
            EventBus.addEventListener(VideoControls.PLAY, function () { return _this.playVideo(); }, this);
            EventBus.addEventListener(VideoControls.NAVIGATE_TO, function (t) { return _this.navigateToTime(t); }, this);
        }
        else {
            this.video.setAttribute("controls", "controls");
            this.video.setAttribute("poster", "/img/video_poster_two.jpg");
            if (VideoView.PHASE == VideoView.PHASE_TWO) {
                this.video.setAttribute("poster", "/img/video_poster_two.jpg");
            }
            this.content.find('.videoBg').hide();
            this.showOptions();
        }
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
    }
    VideoPlayer.prototype.checkKey = function (e) {
        if (e.keyCode == 32) {
            if (this.videoControls.isPlaying) {
                this.pauseVideo();
            }
            else {
                this.playVideo();
            }
        }
    };
    VideoPlayer.prototype.setPhase = function () {
        if (VideoView.PHASE == VideoView.PHASE_TWO) {
            this.btnCreate.html("Create Another");
            this.gaPhase = 'phase_two_video';
        }
    };
    VideoPlayer.prototype.reset = function () {
        this.video.currentTime = 0;
    };
    VideoPlayer.prototype.navigateToTime = function (t) {
        this.video.currentTime = t;
    };
    VideoPlayer.prototype.createAnother = function () {
        Main.isOwner = false;
        VideoView.VIN = "";
        var route = (!Main.phaseTwoLive && VideoView.PHASE == VideoView.PHASE_TWO) ? '/photo' : '/info';
        ga('send', 'event', this.gaPhase, 'click', 'create_more');
        page(route);
    };
    VideoPlayer.prototype.showMe = function () {
        var _this = this;
        _super.prototype.showMe.call(this);
        if (!Main.mobile) {
            clearTimeout(this.controlHideTimeout);
            this.content.on('mousemove', function () { return _this.mouseMove(); });
            $(document).on('keypress', function (e) { return _this.checkKey(e); });
        }
        else {
            EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundController.WHITE);
        }
    };
    VideoPlayer.prototype.mouseMove = function () {
        var _this = this;
        clearTimeout(this.controlHideTimeout);
        this.controlHideTimeout = setTimeout(function () { return _this.hideOptions(); }, 1800);
        this.showOptions();
    };
    VideoPlayer.prototype.showOptions = function () {
        this.videoOptions.addClass('show');
    };
    VideoPlayer.prototype.hideOptions = function () {
        this.videoOptions.removeClass('show');
    };
    VideoPlayer.prototype.setThanks = function () {
    };
    VideoPlayer.prototype.videoOver = function () {
        this.dispatch(VideoPlayer.VIDEO_COMPLETE);
    };
    VideoPlayer.prototype.playVideo = function () {
        this.video.play();
    };
    VideoPlayer.prototype.pauseVideo = function () {
        this.video.pause();
    };
    VideoPlayer.prototype.loadVideo = function (src) {
        var _this = this;
        this.video.src = src;
        if (!Main.mobile) {
            this.playVideo();
        }
        ga('send', 'event', this.gaPhase, 'playback', 'start');
        this.gaFired = 0;
        clearInterval(this.progressInterval);
        this.progressInterval = setInterval(function () {
            var perc = _this.video.currentTime / _this.video.duration;
            if (perc >= 0.75 && _this.gaFired == 2) {
                _this.gaFired = 3;
                ga('send', 'event', _this.gaPhase, 'playback', 'progress', 0.75);
            }
            else if (perc >= 0.50 && _this.gaFired == 1) {
                _this.gaFired = 2;
                ga('send', 'event', _this.gaPhase, 'playback', 'progress', 0.50);
            }
            else if (perc >= 0.25 && _this.gaFired == 0) {
                _this.gaFired = 1;
                ga('send', 'event', _this.gaPhase, 'playback', 'progress', 0.25);
            }
        }, 33);
        if (Main.mobile) {
            this.content.find('.optionContainer').show();
        }
    };
    VideoPlayer.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        clearInterval(this.progressInterval);
        this.pauseVideo();
        if (!Main.mobile) {
            $(document).off('keypress');
            this.hideOptions();
            this.content.off('mousemove');
            clearTimeout(this.controlHideTimeout);
        }
    };
    VideoPlayer.prototype.resize = function () {
        //Desktop
        if (!Main.mobile) {
            var h = (this.isFullscreen) ? 0 : $('.header').height();
            //make the player fullscreen
            var pos = Utils.fitToContainer({
                'containerWidth': Main.sWidth,
                'containerHeight': Main.sHeight - h,
                'contentWidth': this.vidWidth,
                'contentHeight': this.vidHeight,
                'vAlign': 'center',
                'hAlign': 'center',
                'scaleMode': 'proportionalInside'
            });
            pos.top += h;
            this.player.css(pos);
            //make the content fullscreen
            this.content.css({
                'width': Main.sWidth,
                'height': Main.sHeight
            });
        }
        else {
            var pos_2;
            //if landscape, fill screen
            if (Main.sWidth > Main.sHeight) {
                var h = $('.header').height();
                pos_2 = {
                    'width': Main.sWidth,
                    'height': Main.sHeight - h
                };
                this.container.addClass('landscape');
                this.content.css('height', 'auto');
            }
            else {
                var r = this.vidWidth / this.vidHeight;
                var w = screen.width;
                pos_2 = {
                    'width': screen.width,
                    'height': screen.width / r
                };
                this.container.removeClass('landscape');
                this.content.css('height', screen.height);
            }
            this.player.css(pos_2);
        }
    };
    VideoPlayer.prototype.enterFullscreen = function () {
        this.isFullscreen = true;
        this.resize();
    };
    VideoPlayer.prototype.exitFullscreen = function () {
        this.isFullscreen = false;
        this.resize();
    };
    VideoPlayer.prototype.removeExpIdFromUrl = function () {
        var path = this.data.route;
        if (this.data.route == VideoView.PHASE_TWO) {
            path = '/' + this.data.route;
        }
        page(path);
    };
    VideoPlayer.VIDEO_COMPLETE = "videoover";
    VideoPlayer.SHOW = "showvideoplayer";
    VideoPlayer.RESET = "resetvideoplayer";
    VideoPlayer.PLAY = "playvideo";
    return VideoPlayer;
}(Component));
/// <reference path='../scaffolding/DynamicVideoView.ts'/>
/// <reference path='../components/Loader.ts'/>
/// <reference path='../components/Success.ts'/>
/// <reference path='../components/Thanks.ts'/>
/// <reference path='../components/VideoPlayer.ts'/>
var VideoView = (function (_super) {
    __extends(VideoView, _super);
    function VideoView(container, data, id) {
        var _this = this;
        _super.call(this, container, data, id);
        this.videoUrl = "";
        this.setContent(Main.templates.find('.videoView').clone());
        EventBus.addEventListener(VideoView.GENERATE_VIDEO, function (data) { return _this.generateVideo(data); }, this);
        EventBus.addEventListener(VideoView.REWATCH_VIDEO, function () { return _this.rewatch(); }, this);
        this.loader = new Loader(this.content, {}, this);
        this.success = new Success(this.content, {}, this);
        this.videoPlayer = new VideoPlayer(this.content, {}, this);
        this.thanks = new Thanks(this.content, {}, this);
        this.videoPlayer.on(VideoPlayer.VIDEO_COMPLETE, function () { return _this.videoEnded(); }, this);
        $(window).bind('resize orientationchange', function () { return _this.resize(); });
        this.resize();
    }
    VideoView.prototype.shareFacebook = function (e) {
        e.preventDefault();
        var obj = {
            method: 'share',
            href: this.shareUrl
        };
        FB["ui"](obj);
        var phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'facebook');
    };
    VideoView.prototype.shareDownload = function (e) {
        e.preventDefault();
        var name = (VideoView.PHASE == VideoView.PHASE_ONE) ? 'A_Toast_From_The_Barkleys.mp4' : 'Best_Day_Ever.mp4';
        var form = $('<form action="/download" method="post">' +
            '<input type="text" name = "url" value = "' + this.videoUrl + '">' +
            '<input type="text" name = "filename" value = "' + name + '">' +
            '</form>');
        $("#templates").append(form);
        form.submit();
        form.remove();
    };
    VideoView.prototype.shareTwitter = function (e) {
        e.preventDefault();
        var index = (Main.isOwner) ? VideoView.PHASE + "_owner" : VideoView.PHASE;
        var copy = CONFIG.twitter[index];
        var tweetUrl = 'https://twitter.com/intent/tweet';
        tweetUrl += '?url=' + encodeURIComponent(this.shareUrl);
        tweetUrl += '&text=' + copy;
        Utils.openWindow(tweetUrl, 550, 480);
        var phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'twitter');
    };
    VideoView.prototype.shareEmail = function (e) {
        e.preventDefault();
        window.location.href = "mailto:?subject=" + encodeURI(this.emailsub) + "&body=" + encodeURI(this.emailbod);
        var phase = this.makeCategory();
        ga('send', 'event', phase, 'click', 'email');
    };
    VideoView.prototype.videoEnded = function () {
        if (Main.mobile) {
            this.videoPlayer.setThanks();
        }
        else {
            this.videoPlayer.hideMe();
            this.thanks.showMe();
            this.viewShowing = "thanks";
        }
    };
    VideoView.prototype.makeCategory = function () {
        var phase = "phase_one_";
        if (VideoView.PHASE == VideoView.PHASE_TWO) {
            phase = "phase_two_";
        }
        var suffix = "video";
        if (this.viewShowing == 'thanks') {
            suffix = 'thanks';
        }
        phase += suffix;
        return phase;
    };
    VideoView.prototype.loadVideo = function () {
        _super.prototype.loadVideo.call(this);
        this.loader.reset();
        if (this.deeplink) {
            this.loader.showMe();
            this.loader.updatePercentage(0.1);
        }
    };
    VideoView.prototype.setShare = function (src) {
        this.shareUrl = CONFIG.baseUrl + '/video/' + VideoView.PHASE + '/' + this.experienceId;
        if (Main.isOwner) {
            this.shareUrl += "/1";
        }
        //email
        var index = (Main.isOwner) ? VideoView.PHASE + "_owner" : VideoView.PHASE;
        var sub = CONFIG.title[VideoView.PHASE];
        var bod = CONFIG.email[index];
        bod = bod.replace("[url]", this.shareUrl);
        this.emailbod = bod;
        this.emailsub = sub;
    };
    VideoView.prototype.experienceCreated = function (e) {
        var _this = this;
        _super.prototype.experienceCreated.call(this, e);
        this.success.showMe();
        clearTimeout(this.successTimeout);
        this.successTimeout = setTimeout(function () {
            _this.success.hideMe();
            _this.loader.showMe();
        }, 2000);
    };
    VideoView.prototype.generateVideo = function (data) {
        VideoView.PHASE = data.phase;
        this.storyId = this.getStoryId();
        this.data.inv = data.inventory;
        this.createExperience();
    };
    VideoView.prototype.getInventory = function () {
        if (VideoView.VIN && VideoView.PHASE == VideoView.PHASE_ONE) {
            return {
                'vin': VideoView.VIN
            };
        }
        else {
            if (VideoView.VIN) {
                this.data.inv['vin'] = VideoView.VIN;
            }
            return this.data.inv;
        }
    };
    VideoView.prototype.gotScene = function (d) {
        var _this = this;
        setTimeout(function () {
            if (_this.onStage && d.experience_id == _this.experienceId) {
                _this.videoPlayer.showMe();
                _this.viewShowing = 'video';
                var src = void 0;
                if (d.videoUrls.mp4_1080) {
                    src = d.videoUrls.mp4_1080;
                }
                else if (d.videoUrls.mp4_720) {
                    src = d.videoUrls.mp4_720;
                }
                if (Main.mobile) {
                    src = d.videoUrls.mp4_480;
                }
                _this.videoUrl = src;
                // if(document.createElement('video').canPlayType('application/vnd.apple.mpegURL')){
                //     src = d.videoUrls.m3u8;
                // }
                _this.setShare(src);
                _this.videoPlayer.loadVideo(src);
            }
        }, 1000);
    };
    VideoView.prototype.onStatusUpdate = function (e) {
        if (this.onStage && e.id == this.experienceId) {
            var perc = Utils.getPercentage(e.msg);
            if (perc == 0.16666666666666666) {
                perc = 0;
            }
            this.loader.updatePercentage(perc);
        }
    };
    VideoView.prototype.noVideo = function () {
        page('/');
    };
    VideoView.prototype.rewatch = function () {
        this.loadVideo();
    };
    VideoView.prototype.resize = function () {
    };
    VideoView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        var bg = (VideoView.PHASE == VideoView.PHASE_TWO) ? BackgroundImages.Video2 : BackgroundImages.Video1;
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, bg);
    };
    VideoView.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        clearTimeout(this.successTimeout);
        this.success.hideMe();
        this.videoPlayer.hideMe();
        this.loader.hideMe();
        this.thanks.hideMe();
    };
    VideoView.GENERATE_VIDEO = "generatevideo";
    VideoView.REWATCH_VIDEO = "rewatchvideo";
    VideoView.PHASE_ONE = "toast";
    VideoView.PHASE_TWO = "bestdayever";
    return VideoView;
}(DynamicVideoView));
;
/// <reference path='../scaffolding/View.ts'/>
var PhaseTwoIntroView = (function (_super) {
    __extends(PhaseTwoIntroView, _super);
    function PhaseTwoIntroView(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.setContent(Main.templates.find('.phaseTwoIntro').clone());
        this.nextBtn = this.content.find('.btnGetStarted');
        this.nextBtn.on('click', function () { return _this.changeState(); });
    }
    PhaseTwoIntroView.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Intro2);
    };
    PhaseTwoIntroView.prototype.changeState = function () {
        ga('send', 'event', 'phase_two_landing', 'click', 'create_video');
        if (Main.phaseTwoLive) {
            page('/info');
        }
        else {
            page('/photo');
        }
    };
    return PhaseTwoIntroView;
}(View));
/// <reference path='../scaffolding/Component.ts'/>
var MobileMenu = (function (_super) {
    __extends(MobileMenu, _super);
    function MobileMenu(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.isOff = true;
        this.setContent(Main.templates.find('.mobileMenuWrapper').clone());
        this.menuBtn = this.container.find('.mobileMenu').on('click', function () { return _this.toggleMenu(); });
        this.container.find('.homeBtn').on('click', function () { return _this.homeClicked(); });
    }
    MobileMenu.prototype.showMe = function () {
        _super.prototype.showMe.call(this);
        // this.content.removeClass('off');
    };
    MobileMenu.prototype.toggleMenu = function () {
        console.log(this.content);
        if (this.isOff) {
            this.content.removeClass('off');
            this.isOff = false;
        }
        else {
            this.content.addClass('off');
            this.isOff = true;
        }
    };
    MobileMenu.prototype.homeClicked = function () {
        this.toggleMenu();
        if (VideoView.PHASE == VideoView.PHASE_TWO && !Main.phaseTwoLive) {
            page("/phasetwo");
        }
        else {
            page("/");
        }
        EventBus.dispatch(Header.TOGGLE_MENU);
    };
    MobileMenu.prototype.hideMe = function () {
        _super.prototype.hideMe.call(this);
        // this.content.addClass('off');
    };
    return MobileMenu;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
var Header = (function (_super) {
    __extends(Header, _super);
    function Header(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.setContent($("#container").find('.header'));
        this.hideDelay = 500;
        EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, function () { return _this.showMe(); }, this);
        EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, function () { return _this.hideMe(); }, this);
        EventBus.addEventListener(Header.TOGGLE_MENU, function () { return _this.toggleMenu(); }, this);
        this.menuBtn = this.content.find('.mobileMenu').on('click', function () { return _this.toggleMenu(); });
        this.content.find('.logo').on('click', function () { return _this.subaruClick(); });
        this.content.find('.title').on('click', function () { return _this.barkleysClick(); });
    }
    Header.prototype.toggleMenu = function () {
        if (this.menuBtn.hasClass('close')) {
            this.menuBtn.removeClass('close');
            this.menuBtn.addClass('hamburger');
        }
        else {
            this.menuBtn.removeClass('hamburger');
            this.menuBtn.addClass('close');
        }
    };
    Header.prototype.subaruClick = function () {
        window.open('http://www.subaru.com/', '_blank');
        ga('send', 'event', 'header', 'click', 'subaru_site');
    };
    Header.prototype.barkleysClick = function () {
        ga('send', 'event', 'header', 'click', 'barkleys_site');
        if (VideoView.PHASE == VideoView.PHASE_TWO && !Main.phaseTwoLive) {
            page("/phasetwo");
        }
        else {
            page("/");
        }
    };
    Header.TOGGLE_MENU = "togglemenu";
    return Header;
}(Component));
/// <reference path='../scaffolding/Component.ts'/>
var Footer = (function (_super) {
    __extends(Footer, _super);
    function Footer(container, data, delegate) {
        var _this = this;
        _super.call(this, container, data, delegate);
        this.setContent($("#container").find('.footer'));
        EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, function () { return _this.showMe(); }, this);
        EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, function () { return _this.hideMe(); }, this);
    }
    return Footer;
}(Component));
var BackgroundImages;
(function (BackgroundImages) {
    BackgroundImages[BackgroundImages["Intro1"] = 0] = "Intro1";
    BackgroundImages[BackgroundImages["Intro2"] = 1] = "Intro2";
    BackgroundImages[BackgroundImages["Info"] = 2] = "Info";
    BackgroundImages[BackgroundImages["Thanks"] = 3] = "Thanks";
    BackgroundImages[BackgroundImages["Video1"] = 4] = "Video1";
    BackgroundImages[BackgroundImages["Video2"] = 5] = "Video2";
})(BackgroundImages || (BackgroundImages = {}));
;
var BackgroundController = (function (_super) {
    __extends(BackgroundController, _super);
    function BackgroundController(container, data, del) {
        var _this = this;
        _super.call(this, container, data, del);
        this.imageSources = [
            '/img/intro_bg2x.jpg',
            '/img/intro_phasetwo_bg2x.jpg',
            '/img/info_bg2x.jpg',
            '/img/thanks_bg.jpg',
            '/img/loader_bg2x.jpg',
            '/img/info_phasetwo_bg2x.jpg',
        ];
        this.images = {};
        this.setContent(Main.templates.find('.bg'));
        this.imageContainer = this.content.find('.imgContainer');
        this.whiteBg = this.content.find('.white');
        EventBus.addEventListener(BackgroundController.CHANGE_BACKGROUND, function (img) { return _this.changeBg(img); }, this);
    }
    BackgroundController.prototype.changeBg = function (i) {
        var _this = this;
        var imgStr = BackgroundImages[i];
        if (i == BackgroundController.WHITE) {
            if (this.activeImage) {
                this.activeImage.removeClass('active');
            }
            this.activeStr = BackgroundController.WHITE;
            this.whiteBg.addClass('active');
        }
        else if (imgStr != this.activeStr) {
            this.whiteBg.removeClass('active');
            if (this.images[imgStr]) {
                if (this.activeImage) {
                    this.activeImage.removeClass('active');
                }
                this.activeStr = imgStr;
                this.activeImage = this.images[imgStr];
                this.showBg();
            }
            else {
                var div_1 = $('<div class="img"></div>');
                var img = new Image();
                img.onload = function () {
                    if (_this.activeImage) {
                        _this.activeImage.removeClass('active');
                    }
                    div_1.css('background-image', 'url(' + _this.imageSources[i] + ')');
                    _this.images[imgStr] = div_1;
                    _this.activeImage = div_1;
                    _this.activeStr = imgStr;
                    _this.imageContainer.append(div_1);
                    _this.showBg();
                };
                img.src = this.imageSources[i];
            }
        }
    };
    BackgroundController.prototype.showBg = function () {
        var _this = this;
        clearTimeout(this.bgTimeout);
        this.bgTimeout = setTimeout(function () {
            _this.activeImage.addClass('active');
        }, 10);
    };
    BackgroundController.LOADED = "backgroundsloaded";
    BackgroundController.CHANGE_BACKGROUND = "changebackground";
    BackgroundController.WHITE = "WHITE";
    return BackgroundController;
}(Component));
/// <reference path='./scaffolding/Main.ts'/>
/// <reference path='./views/IntroView.ts'/>
/// <reference path='./views/InfoView.ts'/>
/// <reference path='./views/VideoView.ts'/>
/// <reference path='./views/PhaseTwoIntroView.ts'/>
/// <reference path='./views/PhotoView.ts'/>
/// <reference path='./components/MobileMenu.ts'/>
/// <reference path='./components/Header.ts'/>
/// <reference path='./components/Footer.ts'/>
/// <reference path='./components/BackgroundController.ts'/>
var main = new Main();
var cont = $("#container").show();
console.log("%cPowered by %cImposium v1.0.6 %cimposium.com", "color: white; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#000; padding-top:10px; padding-bottom:4px; padding-left:20px; margin-right:0px;  margin-left:8px; margin-top:4px", "color:#A1B83A; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#000; padding:10px; padding-bottom:4px; padding-left:0px;  padding-right:20px; margin-top:4px", "color:#000; font-size:14px; letter-spacing:2px; text-transform:uppercase; line-height:3.5; font-family:DINCondensed-Bold, helvetica; background-color:#A1B83A; padding:10px; padding-bottom:4px; padding-left:20px;  padding-right:20px; margin-top:4px");
// var fonts = loadCSS( "https://fonts.googleapis.com/css?family=Open+Sans:400,300,800,700,600" );
// var styles = loadCSS( "/css/styles.css" );
// onloadCSS( styles, function() {
//     setTimeout(()=>{
//         page();
//     }, 50);
// });
var header = new Header(cont, {}, this);
header.showMe();
var background = new BackgroundController(cont, {}, this);
if (Main.mobile) {
    cont.addClass('mobile');
    var menu = new MobileMenu(this.cont, {}, this);
}
else {
    var footer = new Footer(cont, {}, this);
    footer.showMe();
}
var introRoute = (Main.phaseTwoLive) ? '/' : '/phasetwo';
var infoRoute = (Main.phaseTwoLive) ? '/info' : '/photo';
var introViewTwo = new PhaseTwoIntroView(cont, {
    'route': introRoute
}, this);
var photoView = new PhotoView(cont, {
    'route': infoRoute
}, this);
if (!Main.phaseTwoLive) {
    var introView = new IntroView(cont, {
        'route': '/'
    }, this);
    var infoView = new InfoView(cont, {
        'route': '/info'
    }, this);
}
var videoView = new VideoView(cont, {
    'route': '/video',
    'toast': ['97cdf90e-4a2f-42d3-9ac5-136a189e8bcf', '97cdf90e-4a2f-42d3-9ac5-136a189e8bba'],
    'toast_owner': ['97cdf90e-4a2f-42d3-9ac5-136a189e8bcc', '97cdf90e-4a2f-42d3-9ac5-136a189e8bcd', '97cdf90e-4a2f-42d3-9ac5-136a189e8bce'],
    'bestdayever': ['d54ac9f7-2714-4ef1-ede7-d4fca4b9033d'],
    'bestdayever_owner': ['d54ac9f7-2714-4ef1-ede7-d4fca4b9033d']
}, this);
page();
//facebook
window["fbAsyncInit"] = function () {
    FB.init({
        appId: CONFIG.facebookAppId,
        xfbml: true,
        version: 'v2.6'
    });
};
(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "//connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));
