/// <reference path='../scaffolding/Component.ts'/>

class Header extends Component {

	private menuBtn:JQuery;
    public static TOGGLE_MENU:string = "togglemenu";

    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent($("#container").find('.header'));

        this.hideDelay = 500;

        EventBus.addEventListener(VideoControls.EXIT_FULLSCREEN, ()=>this.showMe(), this);
        EventBus.addEventListener(VideoControls.ENTER_FULLSCREEN, ()=>this.hideMe(), this);
        EventBus.addEventListener(Header.TOGGLE_MENU, ()=>this.toggleMenu(), this);
        this.menuBtn = this.content.find('.mobileMenu').on('click',()=>this.toggleMenu());
        this.content.find('.logo').on('click',()=>this.subaruClick());
        this.content.find('.title').on('click',()=>this.barkleysClick());
    }

    private toggleMenu(){
    	if(this.menuBtn.hasClass('close')){
    		this.menuBtn.removeClass('close');
    		this.menuBtn.addClass('hamburger');
    	} else{
    		this.menuBtn.removeClass('hamburger');
    		this.menuBtn.addClass('close');
    	}
    }

    private subaruClick(){
        window.open('http://www.subaru.com/','_blank' );
        ga('send', 'event', 'header', 'click', 'subaru_site');
    }

    private barkleysClick(){
        ga('send', 'event', 'header', 'click', 'barkleys_site');
        if(VideoView.PHASE == VideoView.PHASE_TWO && !Main.phaseTwoLive){
            page("/phasetwo");
        } else {
            page("/");
        }
    }

}
