/**
 * Global event listener class. Used for passing global between views and components.
 */
class EventBus {

    /**
     * Object containing all event listeners, with the key being the event string, and the value being an array of listener functions.
     */
    static listeners:any = {};

    /**
     * Adds an event listener for a global event.
     *
     * @param evt      Event string we're listening for
     * @param callback The callback function to call if the event is dispatched.
     * @param caller   Who called this
     */
    static addEventListener(evt:string, callback:any, caller:any):void {

        if (!EventBus.listeners[evt]) {
            EventBus.listeners[evt] = {};
        }

        EventBus.listeners[evt][caller.uuid] = callback;
    }

    /**
     * Removes a specific event listener for a global event.
     *
     * @param evt      Event string we're removing
     * @param callback The callback function we want to remove.
     * @param caller   Who called this
     */
    static removeEventListener(evt:string, callback:any, caller:any):void {

        var listeners = EventBus.listeners[evt];

        delete listeners[caller.uuid];
    }

    /**
     * Adds an event listener for a global event.
     *
     * @param evt  The event we want to dispatch.
     * @param data The data we want to pass into the callback function for that event.
     */
    static dispatch(evt:string, data:any = null):void {

        var listeners = EventBus.listeners[evt];

        for (var key in listeners) {
            if (listeners.hasOwnProperty(key)) {
                listeners[key](data);
            }
        }
    }
}
