    /// <reference path='../scaffolding/View.ts'/>

class InfoView extends View {

    private nextBtn:JQuery;
    private inputName:JQuery;
    private inputOccasion:JQuery;
    private inputError:JQuery;
    private termsBox:JQuery;

    private nameString: string = "";
    private occasionString: string = "";
    constructor(container:JQuery, data:any, delegate:any) {

        super(container, data, delegate);

        this.setContent(Main.templates.find('.infoView'));

        this.nextBtn = this.content.find('.btnGenerate');
        this.inputName = this.content.find('.name');
        this.inputOccasion = this.content.find('.occasion');
        
        this.termsBox = this.content.find('.terms').on('click', ()=>this.termsToggle());

        this.nextBtn.on('click', ()=>this.moderateText());
        this.inputName.on('focus', (e)=>this.removeError(e));
        this.inputOccasion.on('focus', (e)=>this.removeError(e));
        this.inputOccasion.on('input paste', (e) => this.checkInput(e, '^[a-zA-Z0-9 \s$-]+$', 'occasionString'));
        this.inputName.on('input paste', (e) => this.checkInput(e, '^[a-zA-Z \s$-]+$', 'nameString'));
        this.inputError = this.content.find('.inputError');

        this.hideDelay = 500;
    }
    
    public showMe(){
        super.showMe();
        EventBus.dispatch(BackgroundController.CHANGE_BACKGROUND, BackgroundImages.Info);
    }

    public termsToggle(){
        if(this.termsBox.hasClass('checked')){
            this.termsBox.removeClass('checked');
            this.nextBtn.attr("disabled", "disabled").addClass('disabled');
        } else {
            this.termsBox.addClass('checked');
            this.nextBtn.removeAttr("disabled").removeClass('disabled');
        }
    }

    private checkInventory(){
        if(this.checkValid()){
            let data = {
                inventory:{
                    'first_name':this.inputName.val(),
                    'action':this.inputOccasion.val()
                },
                phase:VideoView.PHASE_ONE,
            }
            ga('send', 'event', 'phase_one_input', 'click', 'continue');
            EventBus.dispatch(VideoView.GENERATE_VIDEO, data);
            this.inputError.css('visibility', 'hidden');
            this.hideMe();
        } else {
            // this.clearInputs();
            this.showError();
        }
    }

    private checkValid(){
        let isValid =  true;
        this.removeError(null);
        if(this.inputName.val().replace(/\s/g, '').length == 0){
            this.inputName.addClass('error');
            isValid = false;
        }
        if(this.inputOccasion.val().replace(/\s/g, '').length == 0){
            this.inputOccasion.addClass('error');
            isValid = false;
        } 
        if(!this.termsBox.hasClass('checked')){
            isValid = false;
        }
        return isValid
    }

    private moderateText(){
        let name = this.inputName.val().toLowerCase();
        let occasion = this.inputOccasion.val().toLowerCase();

        let url = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text='+name
        let urlTwo = 'https://api1.webpurify.com/services/rest/?method=webpurify.live.check&format=json&api_key=db5e840c50d85e7e172a42e1e8a6155a&text='+occasion
        
        let hasBadWords;
        $.when(
            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'jsonp',
                success: (data)=>{
                    let nameResponse = data.rsp.found
                    if(nameResponse == 1){
                        hasBadWords = true;
                       this.inputName.addClass('error');
                    }

                },
                error: function(e, s, m){}
            }),
            $.ajax({ 
                type: 'GET',
                url: urlTwo,
                dataType: 'jsonp',
                success: (data)=>{
                    let occasionResponse = data.rsp.found
                    if(occasionResponse == 1){
                        hasBadWords = true;
                        this.inputOccasion.addClass('error');
                    }
                },          
            })
        ).then(()=>{
            if(!hasBadWords){
                this.checkInventory();
            } else {
                this.showError();
            }
        });
    }

    private showError(){
        this.inputError.css('visibility', 'visible');
    }

    
    private removeError(e){
        if(e){
            $(e.target).removeClass('error')
            this.inputError.css('visibility', 'hidden');
        } else {
            this.inputName.removeClass('error');
            this.inputOccasion.removeClass('error');  
            this.inputError.css('visibility', 'hidden');
        }   
        this.inputError.css('visibility', 'hidden');     
    }

    public hideMe(){
        super.hideMe();
        this.inputError.css('visibility', 'hidden');
        this.termsBox.removeClass('checked');
        this.nextBtn.attr("disabled", "disabled").addClass('disabled');
        this.inputName.removeClass('error');
        this.inputOccasion.removeClass('error'); 
        this.nextBtn.removeClass('hover'); 
    }

    private checkInput(evt, r, field){

        let regex = new RegExp(r);
        let input = $(evt.target);

        if(evt.type == "input"){

            let val = input.val();

            if (regex.test(val) || val === ""){
                this.inputError.css('visibility', 'hidden');
                this[field] = val;
            }else{
                input.val(this[field]);
            }

        }else if(evt.type == "paste"){

            let paste = evt.originalEvent.clipboardData.getData('Text');
            if (!regex.test(paste)) {
                return false;
            }else{
                this.inputError.css('visibility', 'hidden');                
                return true;
            }
        }
    }

    private clearInputs(){
        this.inputName.val("");
        this.inputOccasion.val("");
    }
}
