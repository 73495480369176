/// <reference path='../scaffolding/Component.ts'/>
/// <reference path='./RecordHandler.ts'/>
/// <reference path='./CountDown.ts'/>

class Record extends Component {
    private canvas:JQuery;
    private temp:JQuery;
    private videoDiv:JQuery;
    private captureBtn:JQuery;
    private backBtn:JQuery;
    private instructions:JQuery;
    private flash:JQuery;
    private overlay:JQuery;
    private recordHandler:RecordHandler;
    private countDown:CountDown;
    private holeWidth = 540;
    private holeHeight = 700;

    constructor(container:JQuery, data:any, delegate:any) {
        super(container, data, delegate);
        this.setContent(Main.templates.find('.recordWrap').clone());

        this.captureBtn = this.content.find('.btnCapture').on('click', ()=>this.startCapture());
        this.backBtn = this.content.find('.btnRecordBack').on('click', ()=>this.goBack());
        this.videoDiv =  this.content.find('video');
        this.flash = this.content.find('.flash');
        this.overlay =  this.content.find('.guideOverlay')
        this.instructions =  this.content.find('.facePrompt');
        
        this.recordHandler = new RecordHandler(this.content, this);
        this.countDown =  new CountDown(this.content.find('.countDownHolder'), this);

        this.resize();
    }

     private showOverlay(){
        this.overlay.show();
    }

    private hideOverlay(){
        this.overlay.hide();
    }

    public showMe(){
        super.showMe();
        this.recordHandler.init();
        this.captureBtn.show();
        this.container.scrollTop(0);
        this.container.css('overflow', 'hidden');
    }

    public startCapture(){
        this.captureBtn.hide();
        this.instructions.hide();
        this.countDown.show();
        ga('send', 'event', 'phase_two_record', 'click', 'take_photo');
    }

    private goBack(){
        this.instructions.show();
        this.countDown.hide();
        EventBus.dispatch(PhotoView.SHOW_PHOTO);
        ga('send', 'event', 'phase_two_adjust', 'click', 'go_back');
    }

    public takePhoto(){
        this.flash.addClass('pluse');
        this.recordHandler.stopCapture();
        ga('send', 'event', 'phase_two_record', 'click', 'photo_taken');

        setTimeout(()=>{
            this.flash.removeClass('pluse');
        }, 150);
    }

    public imageLoaded(inv){
        inv['first_name'] =  this.delegate.firstName;
        let box = {
            'x':(window.innerWidth - this.holeWidth) / 2,
            'y':(window.innerHeight - this.holeWidth) / 2,
            'width':this.holeWidth,
            'height':this.holeHeight
        };
        let data = {
            'box':box,
            'type':'record',
            'content':inv
        }
        EventBus.dispatch(PhotoView.CHECK_DATA, data);
    }

    public camError(){

        EventBus.dispatch(PhotoView.SHOW_PHOTO);
    }


    public hideMe(){

        super.hideMe();
        this.recordHandler.resetState();
        this.container.css('overflow', 'auto');
    }

    public resize(){
        // let width = window.innerWidth;
        // let height = window.innerHeight;
        // if(this.videoDiv){
        //     let pos = Utils.fitToContainer({
        //         'containerWidth':width,
        //         'containerHeight':height,
        //         'contentWidth':width,
        //         'contentHeight':height,
        //         'scaleMode':'proportionalInside',
        //         'hAlign':'center',
        //         'vAlign':'center'
        //     });
        //     this.videoDiv.css({'left':pos.left, 'width':pos.width, 'top':pos.top});
        // }
    }
}
