/// <reference path='./View.ts'/>
/// <reference path='./EventBus.ts'/>
/// <reference path='../views/PhotoView.ts'/>

abstract class DynamicVideoView extends View{

    private imposium:any;
    public experienceId:string;
    public static PHASE_SET:string = "phaseset";
    
    public isReload:boolean = false;
    public abstract getInventory():any;
    public abstract noVideo():void;
    public abstract onStatusUpdate(status):void;
    public abstract gotScene(data):void;

    public storyId:string;
    public deeplink:boolean = true;

    constructor(content, data, del){

        super(content, data, del);  

        var key = 'Kahrongorie8wuo3oorefuv7iesh4yo4';
        this.imposium = new Imposium.ImposiumClient(key);
        this.imposium.on(Imposium.events.STATUS, (e)=>this.onStatusUpdate(e), this);
    }

    public setRoutes(){

        page(this.data.route + '/:phase?/:expId?/:owner?', req =>{

            let phase = req.params.phase;
            let expId = req.params.expId;
            if(expId && phase){

                //EXPERIENCE
                if(expId.length == 36){

                    //If Phase One URL, redirect to info page
                    if(phase == VideoView.PHASE_ONE){
                        page('/info');
                        return;
                    }

                    //set config
                    Main.isOwner = (req.params.owner) ? true : false;
                    VideoView.PHASE = phase;
                    this.experienceId = expId;

                    //load the video
                    EventBus.dispatch(DynamicVideoView.PHASE_SET);
                    this.loadVideo();

                //If VIN, rerdirect to info page
                } else if(expId.length == 17) {

                    page('/info');
                    return;
                    
                    //set config
                    // VideoView.VIN = expId;
                    // Main.isOwner = true;
                    // VideoView.PHASE = phase;

                    // //create the story
                    // if(VideoView.PHASE == VideoView.PHASE_ONE){
                    //     this.storyId = this.getStoryId();
                    //     this.createExperience();
                    // } else { 
                    //     return;
                    // }

                //INVALID
                } else {
                    this.noVideo();
                }

            } else {
                this.noVideo();
            }

            EventBus.dispatch(View.HIDE_ACTIVE);
            this.showMe();
        });
    }

    public getStoryId(){
        //return a story id from a bucket of story id's based off of phase and owner
        var bucket = (Main.isOwner) ? this.data[VideoView.PHASE + '_owner'] : this.data[VideoView.PHASE];
        let id = bucket[Utils.randomFromInterval(0, bucket.length-1)];
        return id;
    }

    public createExperience(){

         let inventory = this.getInventory()
         this.imposium.createExperience(this.storyId, inventory, false, $.proxy(this.experienceCreated, this), $.proxy(this.experienceError, this));
    }

    public experienceCreated(expData){ 
           
        this.deeplink = false;
        let route = this.data.route + '/' + VideoView.PHASE + '/' + expData.id;
        if(Main.isOwner){
            route += "/1";
        }
        page(route);
    }

    public loadVideo(){

        var job = {
            expId: this.experienceId,
            sceneId: 'cc757eea-ded7-49de-a189-5305d9367358',
            actId: '17aad47e-63ab-45c9-b3a9-8e3168f2678c'
        }

        this.imposium.getVideo(
            job,
            $.proxy(this.gotScene, this),
            $.proxy(this.eventProcessorError, this)
        );
    }

    public showMe(){

        super.showMe();
        this.deeplink = true;
    }

    private experienceError(e) {
        console.error("Experience Error");
        console.error(e);
    }

    private eventProcessorError(e){
        console.error("Event Processor Error");
        console.error(e);
    }
}
